.app-container {
  padding: 1em;
  max-width: 800px;
  margin: auto;
  color: #333;
  line-height: 1.6;
}
@media (max-width: 768px) {
  .app-container {
    max-width: 90%; /* Adjust to an appropriate percentage or a fixed pixel value as needed */
  }
}
.app-title {
  text-align: center;
  font-size: 2em;
  margin-bottom: 1em;
}

/* Adapt .app-title for mobile */
@media (max-width: 768px) {
  .app-title {
    font-size: 1.5em; /* Adjust to an appropriate value as needed */
  }
}

.app-description {
  margin-bottom: 1em;
  font-size: 1em;
}

.app-formula {
  margin-left: 1em;
  font-size: 1.2em;
}

.app-button {
  display: block;
  margin: 1em auto;
  padding: 0.5em 1em;
  font-size: 1.2em;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.app-button:hover {
  background-color: #45a049;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f0f0f0;
}

#BlogBody {
  background-color: #d0d0d2;
}
.bouncing-ball-container {
  width: 80%;
  margin: auto;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px;
}

/* Adapt .card for mobile */
@media (max-width: 768px) {
  .card {
    width: 100%; /* Full-width cards on small screens */
  }
}

.card-image {
  width: 100%;
  height: 70%;
  max-height: 200px;
  object-fit: cover;
}

.card-content {
  margin-top: 16px;
}

.card-title {
  font-size: 20px;
  margin-bottom: 8px;
}

.card-snippet {
  font-size: 14px;
  margin-bottom: 16px;
}

button {
  margin-top: 8px;
}

.custom-navbar {
  background-color: #764ba2;
  display: flex;
  justify-content: space-around;
  flex-grow: 1;
}

@media screen and (max-width: 768px) {
  .custom-navbar {
    flex-direction: column;
  }
}

.card-container {
  margin: 30px 0;
  background-color: #f0f0f0;
}

@media (max-width: 768px) {
  .card-container {
    flex-direction: column; /* Stack cards vertically on small screens */
  }
}

.custom-card {
  background-color: #ee6c4d;
  border: none;
  color: #f0f0f0;
}

.custom-button {
  background-color: #f9c74f;
  border: none;
  color: #333;
}

.custom-button:hover {
  background-color: #f8961e;
  border: none;
  color: #f0f0f0;
}
.equation-solver-container {
  width: 80%;
  margin: auto;
}

.app-title {
  text-align: center;
  font-size: 2em;
  margin-bottom: 1em;
}

.app-description {
  margin-bottom: 1em;
  font-size: 1em;
}

.equation-input {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}

.equation-label {
  margin-right: 1em;
}

.equation-textbox {
  width: 200px;
}


.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}


.footer {
  background-color: rgb(9, 10, 9);
  color: white;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerContainer {
  text-align: center;
}
.function-input {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}
.force-arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: 10px;
  background-color: #f0f0f0;
  border-radius: 50%;
}
.function-label {
  margin-right: 1em;
}

.function-textbox {
  width: 200px;
}



.navbar .nav-link:hover {
  color: #ff7b00;
  background-color: #5bb0ad;
  border-radius: 5px;
  }


.navbar .dropdown-menu a {
  font-weight: 500;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #000000;
  font-size: 1.0rem;
}

.navbar .dropdown-menu a:hover {
  color: #f0f0f0;
  background-color: #5bb0ad;
  border-radius: 5px;
  border: 1px solid black; /* Adds border, changing size */

  

}


.text-white{
  font-weight: 600;
  font-size: 1.5rem;
  color: #f0f0f0;
}

.section-image {
  width: 400px;
  height: 400px;
  object-fit: cover;
  margin: 20px auto; /* Adjust the margin as needed */
}

/* Graph Container */
.graphContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: auto;
}

/* Title */
.app-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

/* Description */
.app-description {
  font-size: 16px;
  color: #555;
  text-align: center;
  margin-bottom: 20px;
}

/* Function Input Styles */
.function-input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
}

.function-label {
  font-size: 18px;
  margin-right: 10px;
  color: #444;
}

.function-textbox {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

/* Graph Buttons */
.graph-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.graph-button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  color: #333;
  transition: background-color 0.3s;
}

.graph-button:hover {
  background-color: #e0e0e0;
}
.navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
  background-color: #088D9E;
  padding: .3%;
}




.social-media {
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .social-media {
    width: 100%;
    justify-content: space-around;
  }
}

.social-media a {
  color: #333;
}

.solve-button {
  display: block;
  margin: auto;
  margin-bottom: 2em;
}

.solution-text {
  text-align: center;
  font-size: 1.2em;
}
.greeting{
  height: 20px;
  position: relative;
  bottom: 100px;
}

.white-space {
  height: 3vh; /* Change this value as necessary */
  background-color: white;
}
.WaveInterference-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: #eee;
}

.WaveInterference-title {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.WaveInterference-description {
  margin-bottom: 1rem;
  text-align: center;
  max-width: 800px;
}

.WaveInterference-controls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.WaveInterference-label {
  margin-bottom: 0.5rem;
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.wave-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.controls-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.controls-container div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.equation-display p {
  margin: 0;
  font-family: monospace;
}
.fullscreen-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}


#GreetingText {
  font-size: 2.5rem; /* Large, eye-catching text */
  margin-bottom: 0.5rem; /* Space between title and subtitle */
}

.HeaderSubtitle {
  font-size: 1.2rem; /* Subtitle text size */
  color: #665; /* Subdued text color for contrast */
  margin-bottom: 1rem; /* Space between subtitle and CTA */
}

.HeaderCta {
  font-weight: bold;
  color: #004a99; /* Brand color or a color that stands out */
  background-color: #ffc107; /* A contrasting background color for visibility */
  padding: 10px 20px; /* Padding inside the CTA button */
  border-radius: 5px; /* Rounded corners for the button */
  text-decoration: none; /* Removes underline from the link */
  transition: background-color 0.3s ease; /* Smooth background color transition on hover */
}

.HeaderCta:hover {
  background-color: #e0a800; /* Slightly darker on hover for interactive feedback */
}

/* Existing styles for larger screens */
#GreetingText {
  font-size: 2.5rem; /* Large, eye-catching text */
  margin-bottom: 0.5rem; /* Space between title and subtitle */
}

.HeaderSubtitle {
  font-size: 1.2rem; /* Subtitle text size */
  color: #666; /* Subdued text color for contrast */
  margin-bottom: 1rem; /* Space between subtitle and CTA */
}

.HeaderCta {
  font-weight: bold;
  color: #004a99; /* Brand color or a color that stands out */
  background-color: #ffc107; /* A contrasting background color for visibility */
  padding: 10px 20px; /* Padding inside the CTA button */
  border-radius: 5px; /* Rounded corners for the button */
  text-decoration: none; /* Removes underline from the link */
  transition: background-color 0.3s ease; /* Smooth background color transition on hover */
}

.HeaderCta:hover {
  background-color: #e0a800; /* Slightly darker on hover for interactive feedback */
}

/* Media queries for phone screens */
@media (max-width: 768px) {
  #GreetingText {
    font-size: 1.0rem; /* Slightly smaller text for smaller screens */
    margin-bottom: 0.8rem; /* Less space between title and subtitle */
    text-align: center; /* Center the text on small screens */
  }

  .HeaderSubtitle {
    font-size: 0.5rem; /* Smaller subtitle text size */
    color: #665; /* Keep the same color but you can adjust if needed */
    margin-bottom: 0.8rem; /* Less space between subtitle and CTA */
    text-align: center; /* Center the text on small screens */
  }

  .HeaderCta {
    font-size: 0.9rem; /* Smaller font size for the button text */
    padding: 8px 15px; /* Slightly smaller padding */
    display: block; /* Block display to handle width adjustments */
    width: 80%; /* Set a max-width for the button */
    margin: 0 auto; /* Center the button horizontally */
    text-align: center; /* Ensure text inside the button is centered */
  }
}

/* Main Font Styles */
body {
  font-family: 'Roboto', sans-serif;
  color: #333;
}

h1, h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: inherit; /* Inherits color from specific section styles */
  margin-bottom: 15px;
}

p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.1em;
  line-height: 1.7;
  color: inherit; /* Inherits color from specific section styles */
}

/* Updated Button Styles */
button {
  background-color: #004a99;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1.1em;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
  background-color: #003366;
  transform: translateY(-3px);
}

/* Section Styles with New Typography */
.section-welcome h1,
.section-tools h2,
.section-top-down h2,
.section-real-life h2,
.section-stem h2 {
  font-size: 2.6em;
  letter-spacing: 0.5px;
}

.section-welcome p,
.section-tools p,
.section-top-down p,
.section-real-life p,
.section-stem p {
  font-size: 1.15em;
  line-height: 1.8;
}

/* Responsive Typography */
@media (max-width: 768px) {
  h1, h2 {
    font-size: 2em;
  }

  p {
    font-size: 1em;
  }
}

.button-container {
  position: absolute;
  top: 35%; /* Move the button higher using a percentage */
  right: 20%; /* Keep it aligned to the right with a percentage */
}

.control-button {
  background-color: #007acc;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.control-button:hover {
  background-color: #005b99;
}
