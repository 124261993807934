/* Algebralab3.css - Advanced Function Visualizer Styles */

/* Base Styles and Theme Variables */
.algebra-lab {
    --primary-color: #4285f4;
    --primary-hover: #2a75f3;
    --secondary-color: #34a853;
    --accent-color: #fbbc05;
    --error-color: #ea4335;
    --transition-speed: 0.3s;
    --border-radius: 8px;
    --shadow-sm: 0 2px 8px rgba(0, 0, 0, 0.1);
    --shadow-md: 0 4px 12px rgba(0, 0, 0, 0.15);
    
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--bg-color);
    color: var(--text-color);
    transition: background-color var(--transition-speed), color var(--transition-speed);
  }
  
  /* Light Theme (default) */
  .algebra-lab.light {
    --bg-color: #f8f9fa;
    --card-bg: #ffffff;
    --text-color: #333333;
    --border-color: #e1e4e8;
    --input-bg: #ffffff;
    --button-bg: #f1f3f5;
    --button-hover: #e9ecef;
    --button-text: #333333;
    --button-border: #d0d7de;
    --code-bg: #f1f3f5;
  }
  
  /* Dark Theme */
  .algebra-lab.dark {
    --bg-color: #1a1a1a;
    --card-bg: #2d2d2d;
    --text-color: #e0e0e0;
    --border-color: #444444;
    --input-bg: #3a3a3a;
    --button-bg: #3a3a3a;
    --button-hover: #4a4a4a;
    --button-text: #e0e0e0;
    --button-border: #555555;
    --code-bg: #2a2a2a;
  }
  
  /* Header Styles */
  .algebra-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 2px solid var(--primary-color);
  }
  
  .algebra-header h1 {
    font-size: 28px;
    margin: 0;
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  
  .theme-toggle {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    transition: transform 0.2s, background-color 0.2s;
  }
  
  .theme-toggle:hover {
    background-color: var(--button-hover);
    transform: scale(1.1);
  }
  
  /* Main Content Layout */
  .algebra-content {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  /* Graph Panel Styles */
  .graph-panel {
    flex: 1;
    background-color: var(--card-bg);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-md);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .function-input-container {
    margin-bottom: 10px;
  }
  
  .function-field {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: var(--input-bg);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    padding: 8px 12px;
    box-shadow: var(--shadow-sm);
  }
  
  .function-field label {
    font-size: 18px;
    font-weight: 600;
    color: var(--primary-color);
    white-space: nowrap;
  }
  
  .function-field input {
    flex: 1;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    padding: 8px;
    color: var(--text-color);
  }
  
  .graph-function-btn {
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color var(--transition-speed);
  }
  
  .graph-function-btn:hover {
    background-color: var(--primary-hover);
  }
  
  .graph-function-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .error-message {
    color: var(--error-color);
    font-size: 14px;
    margin-top: 8px;
    padding: 8px;
    background-color: rgba(234, 67, 53, 0.1);
    border-left: 3px solid var(--error-color);
    border-radius: 4px;
  }
  
  .graph-container {
    width: 100%;
    min-height: 400px;
    border-radius: var(--border-radius);
    overflow: hidden;
    box-shadow: var(--shadow-sm);
    background-color: var(--card-bg);
  }
  
  .graph-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  .zoom-controls {
    display: flex;
    gap: 10px;
  }
  
  .zoom-controls button {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid var(--button-border);
    background-color: var(--button-bg);
    color: var(--button-text);
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all var(--transition-speed);
  }
  
  .zoom-controls button:hover {
    background-color: var(--button-hover);
    transform: scale(1.05);
  }
  
  .range-info {
    font-size: 14px;
    color: var(--text-color);
    background-color: var(--button-bg);
    padding: 4px 12px;
    border-radius: 15px;
    box-shadow: var(--shadow-sm);
  }
  
  /* Side Panel Styles */
  .side-panel {
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .history-panel,
  .keyboard-panel,
  .tips-panel {
    background-color: var(--card-bg);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-sm);
    padding: 15px;
  }
  
  .side-panel h4 {
    margin-top: 0;
    margin-bottom: 10px;
    color: var(--primary-color);
    font-size: 16px;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 8px;
  }
  
  .history-toggle {
    width: 100%;
    padding: 8px;
    background-color: var(--button-bg);
    border: 1px solid var(--button-border);
    border-radius: 4px;
    color: var(--button-text);
    font-weight: 600;
    cursor: pointer;
    transition: background-color var(--transition-speed);
  }
  
  .history-toggle:hover {
    background-color: var(--button-hover);
  }
  
  .history-list {
    margin-top: 10px;
  }
  
  .history-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .history-list li {
    margin-bottom: 8px;
  }
  
  .history-list button {
    width: 100%;
    text-align: left;
    padding: 8px;
    background-color: var(--input-bg);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    color: var(--text-color);
    cursor: pointer;
    transition: background-color var(--transition-speed);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .history-list button:hover {
    background-color: var(--button-hover);
  }
  
  .no-history {
    font-style: italic;
    color: #888;
    text-align: center;
  }
  
  .tips-panel ul {
    padding-left: 20px;
    margin-top: 0;
  }
  
  .tips-panel li {
    margin-bottom: 8px;
    font-size: 14px;
  }
  
  .tips-panel code {
    background-color: var(--code-bg);
    padding: 2px 4px;
    border-radius: 3px;
    font-family: 'Courier New', monospace;
    font-size: 0.9em;
  }
  
  /* Footer Styles */
  .algebra-footer {
    margin-top: 30px;
    text-align: center;
    color: #888;
    font-size: 14px;
    padding-top: 15px;
    border-top: 1px solid var(--border-color);
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .algebra-content {
      flex-direction: column;
    }
    
    .side-panel {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
    }
    
    .history-panel,
    .keyboard-panel,
    .tips-panel {
      flex: 1;
      min-width: 250px;
    }
  }
  
  @media (max-width: 768px) {
    .algebra-lab {
      padding: 15px;
    }
    
    .algebra-header h1 {
      font-size: 24px;
    }
    
    .side-panel {
      flex-direction: column;
    }
    
    .function-field {
      flex-wrap: wrap;
    }
    
    .function-field label,
    .function-field input {
      width: 100%;
      margin-bottom: 8px;
    }
    
    .graph-function-btn {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .graph-controls {
      flex-direction: column;
      gap: 10px;
    }
    
    .zoom-controls {
      width: 100%;
      justify-content: center;
    }
    
    .range-info {
      width: 100%;
      text-align: center;
    }
  } 