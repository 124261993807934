/* Global Styles */
body {
    font-family: 'Roboto', sans-serif;
    background-color: #f8f9fa;
    color: #333;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .container-fluid {
    margin-top: 40px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  h1 {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  p {
    font-size: 18px;
    margin-bottom: 30px;
    text-align: center;
    color: #555;
  }
  
  /* Video Section */
  .video-frame {
    margin-bottom: 40px;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .video-container {
    position: relative;
    width: 100%;
    padding-bottom: 55.25%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  
  /* Assessments Section */
  .assessments-container {
    margin-top: 50px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .assessments-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .assessment-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .assessment-item:last-child {
    border-bottom: none;
  }
  
  .start-assessment-button {
    background-color: #5cd39c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .start-assessment-button:hover {
    background-color: #36c180;
  }
  
  /* Card Styles */
  .card {
    margin-top: 40px;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  
  .card-header {
    background-color: #007bff;
    color: white;
    padding: 15px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .card-body {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .playlist-container {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .list-group-item {
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    color: #333;
    border: none;
    background: #f9f9f9;
    transition: background-color 0.3s ease;
  }
  
  .list-group-item:hover {
    background-color: #e9ecef;
  }
  
  /* Sign-in Prompt */
  .sign-in-prompt {
    text-align: center;
    padding: 20px;
    background: #f9f9f9;
    border: 1px dashed #ccc;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }
  
  .sign-in-prompt h2 {
    font-size: 24px;
    color: #333;
  }
  
  .sign-in-prompt button {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .sign-in-prompt button:hover {
    background-color: #218838;
  } 
  
  /* Utility Classes */
  .text-center {
    text-align: center;
  }
  
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .hidden {
    display: none;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    h1 {
        font-size: 28px;
    }
    p {
        font-size: 16px;
    }
    .start-assessment-button {
        font-size: 14px;
        padding: 8px 16px;
    }
    .card-header {
        font-size: 18px;
    }
  }
  