/* -------------------------- Base Styles -------------------------- */

body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f0f4f8;
  }
  
  /* Main app container */
  .anatomy-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    min-height: 100vh;
  }
  
  /* -------------------------- Header -------------------------- */
  .app-header {
    background-color: #ffffff;
    padding: 20px 0;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: #333; /* Visible on white background */
  }
  
  .app-header h1 {
    font-size: 2.5rem;
    margin: 0;
  }
  
  /* -------------------------- Description Section -------------------------- */
  .app-description {
    margin-top: 20px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 70%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    line-height: 1.6;
    color: #333;
  }
  
  .app-description ul {
    list-style-type: none;
    padding: 0;
  }
  
  .app-description li {
    margin: 10px 0;
    font-size: 1rem;
  }
  
  /* -------------------------- Controls / Buttons -------------------------- */
  .app-controls {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
  
  .app-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 15px 25px;
    font-size: 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .app-button:hover {
    background-color: #45a049;
    transform: translateY(-3px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* -------------------------- 3D Canvas -------------------------- */
  /* On larger screens, the canvas will use a 16:9 aspect ratio 
     with a maximum height of 60vh. */
  .app-canvas {
    width: 100%;
    aspect-ratio: 16/9;    /* Responsive 16:9 aspect ratio */
    max-height: 90vh;      /* Prevents the canvas from getting too tall */
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
  }
  
  /* -------------------------- Slider -------------------------- */
  .app-slider {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  #scale-slider {
    width: 200px;
  }
  #VRButton {
      position: absolute;
      bottom: 100px;
  }
  
  canvas {
  
  background-color: #222;
    border-radius: 10px; 
    box-shadow: 0 4px 15px #00000080; 
    display: block;
      height: 80vh;
    width: 80vw;
    margin:auto;
      max-width: 90%; 
    max-height: 90%;
  }
  /* -------------------------- Tooltip -------------------------- */
  .tooltip {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    pointer-events: none;
  }
  
  /* -------------------------- Loading Spinner -------------------------- */
  .loading-container {
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10;
    color: white;
    font-family: Arial, sans-serif;
    pointer-events: none; /* Allow interaction with the canvas behind it */
  }
  
  .loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* -------------------------- Mobile-Friendly Adjustments -------------------------- */
  /* For screens up to ~768px wide. Adjust if needed. */
  @media (max-width: 768px) {
    .anatomy-app {
      padding: 10px;
    }
  
    .app-header h1 {
      font-size: 1.8rem;
    }
  
    .app-description {
      width: 100%;
      padding: 15px;
      font-size: 0.95rem;
      margin-top: 15px;
    }
  
    .app-controls {
      flex-direction: column;
      gap: 10px;
      margin-top: 15px;
    }
  
    .app-button {
      width: 100%;
      padding: 12px;
      font-size: 1rem;
    }
  
    .app-canvas {
      aspect-ratio: auto;
      height: 40vh;
      max-height: none;
      margin-top: 15px;
    }
  
    .app-slider {
      flex-direction: column;
      margin-top: 15px;
    }
  
    #scale-slider {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
      canvas {
          width: 90vw;
          height: auto;
          max-width: 100%;
          max-height: none;
          padding: 20px;
      }
  }
  