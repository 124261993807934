/* Overall page layout */
.video-page-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    gap: 20px;
  }
  
  .video-main-content {
    flex: 1;
    min-width: 300px;
    flex-basis: 75%;
  }
  
  .video-sidebar {
    flex: 0 0 320px;
    background-color: #f5f5f5;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.08);
  }
  
  /* Main Outer Container */
  .video-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eaecdd; /* Light blue-grey background */
    padding: 40px 20px;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  /* Inner Content Container */
  .video-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    width: 100%;
  }
  
  /* Video Container */
  .video-container {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  /* Video Frame */
  .video-frame {
    width: 100%;
    height: 500px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border: none;
  }
  
  /* Metadata Styling */
  .video-metadata {
    text-align: center;
    padding: 0 0 15px 0;
    color: #333333;
    width: 100%;
  }
  
  .video-title {
    font-size: 2em;
    font-weight: bold;
    color: #003366; /* Darker navy for a professional look */
    margin-bottom: 15px;
  }
  
  .video-description {
    font-size: 1.1em;
    line-height: 1.6;
    color: #444444; /* Subtle dark grey for readability */
  }
  
  /* Related Videos Styling */
  .related-videos-title {
    font-size: 1.4em;
    font-weight: 600;
    color: #003366;
    margin-bottom: 15px;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 8px;
  }
  
  .related-videos-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .related-video-item {
    display: flex;
    text-decoration: none;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .related-video-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }
  
  .related-video-thumbnail {
    flex: 0 0 120px;
    height: 80px;
    overflow: hidden;
  }
  
  .related-video-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .related-video-info {
    flex: 1;
    padding: 10px 15px;
  }
  
  .related-video-title {
    font-size: 0.95em;
    color: #333;
    margin: 0;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Back to videos link */
  .back-to-videos {
    display: inline-block;
    margin-top: 10px;
    color: #003366;
    text-decoration: none;
    font-weight: 500;
    padding: 8px 15px;
    background-color: #e6f2ff;
    border-radius: 4px;
    transition: background-color 0.2s ease;
  }
  
  .back-to-videos:hover {
    background-color: #cce5ff;
    text-decoration: underline;
  }
  
  /* Loading and Error States */
  .video-loading, .video-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 12px;
    padding: 40px;
    min-height: 300px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.08);
    width: 100%;
    text-align: center;
  }
  
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #003366;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .video-error p {
    font-size: 1.2em;
    color: #b00020;
    margin-bottom: 20px;
  }
  
  .no-related-videos {
    padding: 15px;
    background-color: #f0f0f0;
    border-radius: 8px;
    text-align: center;
    color: #666;
  }
  
  /* Responsive Design */
  @media (max-width: 1100px) {
    .video-page-container {
      flex-direction: column;
    }
    
    .video-sidebar {
      flex: 0 0 auto;
      width: 100%;
    }
    
    .related-videos-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      gap: 15px;
    }
  }
  
  @media (max-width: 768px) {
    .video-wrapper {
      padding: 20px 15px;
    }
    
    .video-content {
      padding: 15px;
    }
    
    .video-frame {
      height: 350px;
    }
  
    .video-title {
      font-size: 1.5em;
    }
  
    .video-description {
      font-size: 1em;
    }
    
    .related-videos-list {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
  }
  
  @media (max-width: 480px) {
    .video-frame {
      height: 240px;
    }
    
    .video-wrapper {
      padding: 15px 10px;
    }
    
    .related-videos-list {
      grid-template-columns: 1fr;
    }
    
    .related-video-thumbnail {
      flex: 0 0 100px;
      height: 70px;
    }
  }
  