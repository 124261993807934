.pka-description {
  background: white;
  padding: 20px;
  margin: 20px auto;
  width: 50%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.pka-description h2 {
  color: #007bff;
  text-align: center;
}

.pka-description p {
  font-size: 1.1rem;
  color: #333;
}

.protonated {
  color: red;
  font-weight: bold;
}

.deprotonated {
  color: green;
  font-weight: bold;
}
canvas {
  display: block;
  margin: auto;
  width: 80vw; /* Makes it responsive */
  height: 80vh;
  border-radius: 10px; /* Smooth corners */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5); /* Adds a shadow */
  background-color: #222; /* Optional background */
}

