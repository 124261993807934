/* LoginPage.css */

body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f9;
}

#LoginForm {
    text-align: center;
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
}

form {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 400px;
    margin: auto;
}

form h2 {
    margin-bottom: 1rem;
}

form .form-group {
    margin-bottom: 1rem;
}

form .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #555;
}

form .form-group input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1em;
    box-sizing: border-box;
}

form .form-group input:focus {
    border-color: #007bff;
    outline: none;
}

form button {
    display: block;
    width: 100%;
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    font-size: 1em;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

form button:hover {
    background-color: #0056b3;
}

form .py-3 {
    margin-top: 20px;
    text-align: center;
}

form .py-3 a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

form .py-3 a:hover {
    text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
    form {
        max-width: 90%;
        padding: 15px;
    }

    #LoginForm {
        font-size: 1.5em;
    }

    form button {
        padding: 12px;
        font-size: 1.2em;
    }
}

@media (min-width: 768px) {
    form {
        max-width: 400px;
    }
}
  