/* AbilityGraph.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Roboto+Mono:wght@400;500&display=swap');

/* Container styles */
.graphContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 850px;
    margin: 30px auto;
    padding: 25px;
    border-radius: 12px;
    background: #1f2937;
    color: #f9fafb;
    font-family: 'Roboto', sans-serif;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(203, 213, 225, 0.1);
}

.graphContainer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, transparent, rgba(76, 154, 255, 0.3), transparent);
    z-index: 1;
}

/* Graph header section */
.graph-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

/* Title styling */
.app-title {
    color: #4c9aff;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.6em;
    text-align: center;
    margin-bottom: 25px;
    letter-spacing: 0.5px;
}

/* Digital display styling */
.digital-display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #111827;
    padding: 18px 40px;
    border-radius: 8px;
    margin-bottom: 25px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    border: 1px solid rgba(76, 154, 255, 0.2);
    transition: all 0.3s ease-out;
}

.digital-display.animated {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
}

.digital-label {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 0.85em;
    color: rgba(255, 255, 255, 0.7);
    letter-spacing: 1px;
    margin-bottom: 5px;
}

.digital-score {
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    font-size: 2.8em;
    letter-spacing: 1px;
    position: relative;
}

/* Plotly container */
.plotly-container {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
    background: rgba(17, 24, 39, 0.5);
    border: 1px solid rgba(76, 154, 255, 0.15);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Graph footer */
.graph-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
}

/* Skill indicators */
.skill-indicators {
    display: none; /* Hide the duplicate indicators since we now have the scale */
}

/* Skill scale - new component for better visibility */
.skill-scale {
    width: 90%;
    max-width: 700px;
    margin: 0 auto 20px;
    position: relative;
    padding: 20px 0;
}

.scale-line {
    height: 3px;
    background: linear-gradient(
        to right,
        rgba(200, 84, 80, 0.8),
        rgba(209, 130, 55, 0.8),
        rgba(127, 127, 255, 0.8),
        rgba(76, 154, 255, 0.8),
        rgba(62, 180, 137, 0.8)
    );
    width: 100%;
    border-radius: 2px;
}

.scale-labels {
    position: relative;
    height: 60px;
    margin-top: 5px;
    width: 100%;
}

.scale-marker {
    position: absolute;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scale-value {
    font-family: 'Roboto Mono', monospace;
    font-size: 0.75em;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 4px;
}

.scale-label {
    font-family: 'Roboto', sans-serif;
    font-size: 0.8em;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
    white-space: nowrap;
}

/* Adjust the scale marker colors */
.scale-marker:nth-child(1) .scale-label {
    color: rgba(200, 84, 80, 0.9);
}

.scale-marker:nth-child(3) .scale-label {
    color: rgba(209, 130, 55, 0.9);
}

.scale-marker:nth-child(4) .scale-label {
    color: rgba(127, 127, 255, 0.9);
}

.scale-marker:nth-child(5) .scale-label {
    color: rgba(76, 154, 255, 0.9); 
}

.scale-marker:nth-child(6) .scale-label {
    color: rgba(62, 180, 137, 0.9);
}

/* Ada's message styling */
.ada-message {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(17, 24, 39, 0.7);
    border: 1px solid rgba(76, 154, 255, 0.2);
    padding: 15px 25px;
    border-radius: 8px;
    margin-bottom: 20px;
    width: 90%;
    max-width: 700px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.ada-icon {
    background: linear-gradient(135deg, #2563eb, #4c9aff);
    color: white;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    margin-right: 15px;
    font-size: 0.8em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    flex-shrink: 0;
}

.ada-message p {
    font-size: 1em;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    color: #e5e7eb;
}

.interpretation-note {
    font-size: 0.85em;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-family: 'Roboto', sans-serif;
    margin-top: 10px;
}

.interpretation-note p {
    margin: 0;
}

/* Keyframes for fading animation */
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

/* Plotly graph styling */
.plotly {
    background: transparent !important;
}

/* Responsive design for mobile devices */
@media (max-width: 768px) {
    .graphContainer {
        padding: 15px;
        margin: 20px 15px;
    }

    .app-title {
        font-size: 1.4em;
    }

    .digital-display {
        padding: 15px 25px;
    }

    .digital-score {
        font-size: 2.4em;
    }
    
    .skill-scale {
        width: 100%;
        padding: 10px 0;
    }
    
    .scale-labels {
        height: 80px;
    }
    
    .scale-marker {
        transform: translateX(-50%);
    }
    
    .scale-label {
        font-size: 0.7em;
        transform: rotate(-45deg);
        transform-origin: top center;
        margin-top: 5px;
    }
    
    .ada-message {
        flex-direction: column;
        padding: 15px;
    }
    
    .ada-icon {
        margin-right: 0;
        margin-bottom: 10px;
    }
    
    .ada-message p {
        text-align: center;
        font-size: 0.95em;
    }
}
