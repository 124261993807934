/* Modern Assessment CSS with Improved Design */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* General styles */
.cat-container-background {
  background: linear-gradient(135deg, #f0f9ff 0%, #e1f5fe 100%);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  padding: 20px;
  color: #333;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  position: relative;
}

/* Outer Assessment Container */
.outer-assessment-container {
  background: linear-gradient(135deg, #f3e5f5 0%, #e8eaf6 100%);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

/* Assessment Container */
.assessment-container {
  max-width: 900px;
  margin: 40px auto;
  padding: 30px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
}

/* Assessment Heading */
.assessment-heading {
  font-size: 2.5rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 700;
}

/* Questions and Options */
.assessment-question-container {
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-left: 5px solid #3498db;
  border-radius: 12px;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.assessment-question {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #34495e;
  line-height: 1.6;
}

.assessment-question-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1rem auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.assessment-question-image:hover {
  transform: scale(1.02);
}

/* Assessment Choices */
.assessment-choice {
  background-color: #ffffff;
  padding: 1rem 1.25rem;
  margin-bottom: 0.75rem;
  border: 1px solid #e1e8ed;
  border-radius: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
}

.assessment-choice:hover {
  background-color: #f0f7fa;
  border-color: #c2cfd6;
  transform: translateY(-2px);
}

.assessment-choice input[type='radio'],
.assessment-choice input[type='checkbox'] {
  margin-right: 0.75rem;
  transform: scale(1.2);
}

.assessment-choice label {
  width: 100%;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
}

/* Choice Images */
.assessment-choice-image {
  max-width: 300px;
  max-height: 300px;
  display: block;
  margin: 12px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.assessment-choice-image:hover {
  transform: scale(1.03);
}

/* Navigation Buttons */
.assessment-navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.assessment-button {
  padding: 0.85rem 1.75rem;
  font-size: 1rem;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.assessment-button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

/* Previous and Submit Buttons */
.assessment-previous-button,
.assessment-submit-button {
  padding: 12px 24px;
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  background: linear-gradient(to right, #5cb85c, #4cae4c);
  border: none;
  border-radius: 30px;
  box-shadow: 0 6px 12px rgba(76, 175, 80, 0.3);
  transition: all 0.3s ease;
  cursor: pointer;
  outline: none;
}

.assessment-previous-button:hover,
.assessment-submit-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 15px rgba(76, 175, 80, 0.4);
}

.assessment-previous-button:active,
.assessment-submit-button:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(76, 175, 80, 0.2);
}

/* CAT Container */
.cat-container {
  max-width: 800px;
  width: 100%;
  margin: 20px auto;
  padding: 30px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 0.5s ease-out;
}

/* Assessment Header */
.assessment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  flex-wrap: wrap;
}

/* Timer Display */
.timer-display {
  background: linear-gradient(135deg, #4361ee, #3a0ca3);
  color: white;
  padding: 10px 20px;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  box-shadow: 0 4px 15px rgba(67, 97, 238, 0.25);
  animation: pulse 2s infinite;
}

.timer-icon {
  margin-right: 8px;
  font-style: normal;
}

@keyframes pulse {
  0% {
    box-shadow: 0 4px 15px rgba(67, 97, 238, 0.25);
  }
  50% {
    box-shadow: 0 4px 20px rgba(67, 97, 238, 0.4);
  }
  100% {
    box-shadow: 0 4px 15px rgba(67, 97, 238, 0.25);
  }
}

/* Question Count */
.question-count {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  padding: 8px 16px;
  background-color: #f0f7ff;
  border-radius: 8px;
  border-left: 4px solid #4361ee;
}

/* Assessment Body */
.assessment-body {
  margin-bottom: 30px;
}

/* Question Content */
.question-content {
  text-align: left;
  margin-bottom: 25px;
}

/* Question Image Container */
.question-image-container {
  margin: 20px 0;
  text-align: center;
}

/* Question Text */
.question-text {
  font-size: 22px;
  line-height: 1.6;
  color: #2c3e50;
  margin-bottom: 24px;
  font-weight: 600;
  padding: 0 10px;
}

/* Answer Options */
.answer-options {
  margin-top: 20px;
}

/* Choices Container */
.choices-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Short Answer Container */
.short-answer-container {
  margin: 20px 0;
}

.short-answer-input {
  width: 100%;
  padding: 16px 20px;
  font-size: 16px;
  border: 2px solid #e1e8ed;
  border-radius: 10px;
  background-color: white;
  transition: all 0.3s ease;
  color: #333;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.02);
}

.short-answer-input:focus {
  outline: none;
  border-color: #4361ee;
  box-shadow: 0 5px 15px rgba(67, 97, 238, 0.1);
}

/* Assessment Actions */
.assessment-actions {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
  flex-wrap: wrap;
}

/* Loading State */
.cat-container.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(67, 97, 238, 0.2);
  border-top: 5px solid #4361ee;
  border-radius: 50%;
  margin-bottom: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Ability Score Container */
.ability-score-container {
  margin-top: 30px;
  padding: 20px;
  background-color: #fbfbfd;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

/* Submit Button */
.cat-submit-button {
  background: linear-gradient(to right, #4CAF50, #2E7D32);
  color: white;
  border: none;
  padding: 14px 30px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 6px 12px rgba(76, 175, 80, 0.25);
}

.cat-submit-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 15px rgba(76, 175, 80, 0.35);
}

.cat-submit-button:active {
  transform: translateY(0);
  box-shadow: 0 3px 8px rgba(76, 175, 80, 0.2);
}

/* End Test Button */
.cat-end-test-button {
  background-color: #fff;
  color: #F44336;
  border: 2px solid #F44336;
  padding: 14px 30px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 4px 10px rgba(244, 67, 54, 0.1);
}

.cat-end-test-button:hover {
  background-color: #F44336;
  color: white;
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(244, 67, 54, 0.2);
}

/* Ada Completion Message */
.ada-completion-message {
  background: linear-gradient(135deg, #4361ee, #3a0ca3);
  color: white;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  margin: 20px 0;
  font-weight: 500;
  box-shadow: 0 6px 15px rgba(67, 97, 238, 0.25);
  animation: fadeIn 0.8s ease;
}

/* Ada Message */
.ada-message {
  font-size: 1.1rem;
  line-height: 1.7;
  color: #2c3e50;
  text-align: center;
  margin: 20px 0;
  padding: 15px;
  background-color: #f0f7ff;
  border-radius: 10px;
}

.ada-message p {
  margin-bottom: 10px;
}

/* Question Count */
.question-count {
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0;
  color: #333;
  display: inline-block;
  position: relative;
  padding: 8px 16px;
  background-color: #f0f7ff;
  border-radius: 8px;
  border-left: 4px solid #4361ee;
}

/* Question Text */
h3 {
  font-size: 22px;
  line-height: 1.6;
  color: #2c3e50;
  margin-bottom: 24px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  padding: 0 20px;
  text-align: left;
  animation: fadeIn 0.6s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.choices:nth-child(1) { animation-delay: 0.1s; }
.choices:nth-child(2) { animation-delay: 0.2s; }
.choices:nth-child(3) { animation-delay: 0.3s; }
.choices:nth-child(4) { animation-delay: 0.4s; }

/* Short Answer Input */
input[type="text"] {
  width: 100%;
  padding: 16px 20px;
  font-size: 16px;
  border: 2px solid #e1e8ed;
  border-radius: 10px;
  background-color: white;
  transition: all 0.3s ease;
  color: #333;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.02);
  margin: 10px 0 20px;
}

input[type="text"]:focus {
  outline: none;
  border-color: #4361ee;
  box-shadow: 0 5px 15px rgba(67, 97, 238, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .cat-container {
    padding: 20px;
    margin: 10px;
  }
  
  .assessment-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  
  .timer-display {
    width: 100%;
    justify-content: center;
  }
  
  .question-count {
    width: 100%;
    text-align: center;
  }
  
  .assessment-actions {
    flex-direction: column;
    gap: 10px;
  }
  
  .cat-submit-button, .cat-end-test-button {
    width: 100%;
  }
  
  .ability-score-container {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .cat-container {
    padding: 15px;
  }
  
  .question-text {
    font-size: 18px;
  }
  
  .choices {
    padding: 12px 15px;
  }
  
  .assessment-question-image, .assessment-choice-image {
    max-width: 100%;
  }
}

/* CAT Heading */
.cat-heading {
  font-size: 2rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: 700;
  position: relative;
}

.cat-heading::after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background: linear-gradient(to right, #4361ee, #3a0ca3);
  margin: 10px auto 0;
  border-radius: 2px;
}

/* Choice Styles for CAT */
.choices {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 16px 20px;
  margin-bottom: 5px;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: left;
  animation: fadeIn 0.5s ease forwards;
  opacity: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.choices:hover {
  background-color: #f0f7ff;
  border-color: rgba(67, 97, 238, 0.2);
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.choices input[type='radio'],
.choices input[type='checkbox'] {
  margin-right: 15px;
  transform: scale(1.2);
  cursor: pointer;
  accent-color: #4361ee;
}

.choices input[type='radio']:checked + label,
.choices input[type='checkbox']:checked + label {
  color: #4361ee;
  font-weight: 600;
}

.choices label {
  flex: 1;
  cursor: pointer;
  font-weight: 500;
  color: #333;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.choices:nth-child(1) { animation-delay: 0.1s; }
.choices:nth-child(2) { animation-delay: 0.2s; }
.choices:nth-child(3) { animation-delay: 0.3s; }
.choices:nth-child(4) { animation-delay: 0.4s; }