/* Reset and Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    font-family: 'Poppins', 'Segoe UI', 'Arial', sans-serif;
    background: #f4f5f7;
    color: #333;
    overflow-x: hidden;
}

/* Outer Container */
.outer-quiz-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
    padding: 30px;
    position: relative;
    overflow: hidden;
}

/* Background Elements */
.outer-quiz-container::before {
    content: '';
    position: absolute;
    top: -50%;
    right: -50%;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%);
    transform: rotate(-15deg);
    z-index: 0;
}

.outer-quiz-container::after {
    content: '';
    position: absolute;
    bottom: -30%;
    left: -30%;
    width: 80%;
    height: 80%;
    background: radial-gradient(circle, rgba(255,255,255,0.08) 0%, rgba(255,255,255,0) 60%);
    z-index: 0;
}

/* Quiz Container */
.quiz-container {
    margin-top: 20%;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 20px;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.15), 
                0 10px 20px rgba(0, 0, 0, 0.1),
                0 0 0 1px rgba(255, 255, 255, 0.1);
    padding: 40px;
    max-width: 900px;
    width: 100%;
    text-align: center;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    overflow: hidden;
    position: relative;
    backdrop-filter: blur(10px);
    z-index: 1;
    margin-right:7%;
}

.quiz-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 6px;
    background: linear-gradient(90deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    z-index: 2;
}

.quiz-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 30px 70px rgba(0, 0, 0, 0.2), 
                0 15px 30px rgba(0, 0, 0, 0.15),
                0 0 0 1px rgba(255, 255, 255, 0.15);
}

/* Quiz Header */
.quiz-heading {
    font-size: 2.5rem;
    font-weight: 700;
    background: linear-gradient(90deg, #5E35B1, #1976D2);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: 30px;
    padding-bottom: 10px;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.quiz-heading::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: linear-gradient(90deg, #5E35B1, #1976D2);
    border-radius: 2px;
}

/* Question Section */
.quiz-question-container {
    margin-bottom: 40px;
    padding: 25px;
    background: rgba(245, 247, 250, 0.7);
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    position: relative;
    overflow: hidden;
}

.quiz-question-container::before {
    content: '?';
    position: absolute;
    top: -20px;
    right: -20px;
    font-size: 120px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.03);
    z-index: 0;
}

.quiz-question {
    font-size: 1.8rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 25px;
    position: relative;
    z-index: 1;
    text-align: left;
    line-height: 1.4;
}

/* Choices */
.choices-list {
    display: grid;
    gap: 16px;
    margin-top: 30px;
}

.choice-item {
    display: flex;
    align-items: center;
    padding: 18px 20px;
    font-size: 1.25rem;
    font-weight: 500;
    background: white;
    border: 2px solid #e0e0e0;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.choice-item:hover {
    background: #f5f9ff;
    border-color: #6a11cb;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(106, 17, 203, 0.15);
}

.choice-item input[type="radio"],
.choice-item input[type="checkbox"] {
    margin-right: 15px;
    cursor: pointer;
    position: relative;
    z-index: 2;
}

/* Custom styling for checkbox and radio */
.choice-item input[type="checkbox"],
.choice-item input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    width: 22px;
    height: 22px;
    background: white;
    border: 2px solid #6a11cb;
    position: relative;
    transition: all 0.2s ease;
}

.choice-item input[type="checkbox"] {
    border-radius: 6px;
}

.choice-item input[type="radio"] {
    border-radius: 50%;
}

.choice-item input[type="checkbox"]:checked,
.choice-item input[type="radio"]:checked {
    background: #6a11cb;
    border-color: #6a11cb;
}

.choice-item input[type="checkbox"]:checked::after {
    content: '✓';
    position: absolute;
    color: white;
    font-size: 14px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.choice-item input[type="radio"]:checked::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Highlight states for correct/wrong answers */
.correct-answer {
    background: linear-gradient(to right, rgba(76, 175, 80, 0.2), rgba(76, 175, 80, 0.05)) !important;
    border-color: #4caf50 !important;
    box-shadow: 0 5px 15px rgba(76, 175, 80, 0.2) !important;
    animation: highlight-correct 1s ease;
}

.correct-answer::before {
    content: '✓';
    position: absolute;
    right: 20px;
    color: #4caf50;
    font-size: 24px;
    font-weight: bold;
}

.wrong-answer {
    background: linear-gradient(to right, rgba(244, 67, 54, 0.2), rgba(244, 67, 54, 0.05)) !important;
    border-color: #f44336 !important;
    box-shadow: 0 5px 15px rgba(244, 67, 54, 0.2) !important;
    animation: highlight-wrong 1s ease;
}

.wrong-answer::before {
    content: '✗';
    position: absolute;
    right: 20px;
    color: #f44336;
    font-size: 24px;
    font-weight: bold;
}

@keyframes highlight-correct {
    0% { background-color: #ffffff; }
    50% { background-color: rgba(76, 175, 80, 0.3); }
    100% { background: linear-gradient(to right, rgba(76, 175, 80, 0.2), rgba(76, 175, 80, 0.05)); }
}

@keyframes highlight-wrong {
    0% { background-color: #ffffff; }
    50% { background-color: rgba(244, 67, 54, 0.3); }
    100% { background: linear-gradient(to right, rgba(244, 67, 54, 0.2), rgba(244, 67, 54, 0.05)); }
}

/* Submit Button */
.submit-answer-button {
    display: inline-block;
    width: 100%;
    padding: 16px;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    background: linear-gradient(45deg, #6a11cb 0%, #2575fc 100%);
    border: none;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 30px;
    transition: all 0.3s ease;
    box-shadow: 0 10px 20px rgba(37, 117, 252, 0.2);
    position: relative;
    overflow: hidden;
}

.submit-answer-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, 
                rgba(255, 255, 255, 0), 
                rgba(255, 255, 255, 0.2), 
                rgba(255, 255, 255, 0));
    transition: all 0.6s ease;
}

.submit-answer-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 15px 25px rgba(37, 117, 252, 0.3);
    background: linear-gradient(45deg, #5515a5 0%, #2061d6 100%);
}

.submit-answer-button:hover::before {
    left: 100%;
}

.submit-answer-button:disabled {
    background: linear-gradient(45deg, #9e9e9e, #757575);
    cursor: not-allowed;
    opacity: 0.7;
    box-shadow: none;
    transform: none;
}

/* Connected Users */
.connected-users-container {
    padding: 25px;
    margin-top: 40px;
    border-top: 2px solid rgba(0, 0, 0, 0.05);
    background: rgba(245, 247, 250, 0.7);
    border-radius: 15px;
}

.connected-users-container h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #2c3e50;
    font-weight: 600;
}

.user-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 20px;
    justify-content: center;
}

.user-card {
    background: white;
    border: none;
    border-radius: 16px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
    padding: 20px 15px;
    text-align: center;
    width: auto;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.user-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: linear-gradient(90deg, #FF512F, #F09819);
    transition: height 0.3s ease;
}

.user-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.12);
}

.user-card:hover::before {
    height: 8px;
}

.user-card-username {
    font-size: 1.2rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 8px;
}

.user-card-score {
    font-size: 2rem;
    font-weight: 700;
    background: linear-gradient(90deg, #6a11cb, #2575fc);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-top: 10px;
}

/* Countdown Timer */
.countdown {
    font-size: 3rem;
    font-weight: 700;
    margin: 20px 0;
    background: linear-gradient(90deg, #FF512F, #F09819);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: pulse 1s infinite;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    box-shadow: 0 10px 30px rgba(255, 81, 47, 0.2);
    position: relative;
}

.countdown::before, .countdown::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50px;
    background: linear-gradient(90deg, rgba(255, 81, 47, 0.1), rgba(240, 152, 25, 0.1));
    z-index: -1;
    animation: pulse-shadow 1s infinite alternate;
}

.countdown::after {
    animation-delay: 0.5s;
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

@keyframes pulse-shadow {
    0% { 
        transform: scale(1);
        opacity: 0.5;
    }
    100% { 
        transform: scale(1.2);
        opacity: 0;
    }
}

/* Loading & Messages */
.loading, .waiting-message, .error {
    font-size: 1.5rem;
    font-weight: 500;
    padding: 30px;
    border-radius: 16px;
    background: rgba(255,255,255,0.9);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    margin: 30px 0;
}

.loading {
    color: #2575fc;
    position: relative;
}

.loading::after {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #2575fc;
    margin: 20px auto 0;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.waiting-message {
    color: #F09819;
}

.error {
    color: #f44336;
    border-left: 5px solid #f44336;
}

/* Short Answer Input */
.short-answer-form {
    margin-top: 25px;
    width: 100%;
    position: relative;
}

.short-answer-input {
    width: 100%;
    padding: 18px 20px;
    font-size: 1.2rem;
    border: 2px solid #e0e0e0;
    border-radius: 12px;
    background: white;
    transition: all 0.3s ease;
    font-family: inherit;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.short-answer-input:focus {
    outline: none;
    border-color: #6a11cb;
    box-shadow: 0 5px 20px rgba(106, 17, 203, 0.15);
}

.short-answer-input::placeholder {
    color: #aaa;
    opacity: 0.8;
}

/* Media Queries for Responsiveness */
@media (max-width: 992px) {
    .quiz-container {
        padding: 30px;
        max-width: 90%;
    }
    
    .quiz-heading {
        font-size: 2.2rem;
    }
    
    .quiz-question {
        font-size: 1.6rem;
    }
    
    .countdown {
        font-size: 2.5rem;
    }
}

@media (max-width: 768px) {
    .outer-quiz-container {
        display: flex;
        flex-direction: column;
        height: auto;
        overflow-y: auto;
        background-color: #f4f4f9;
        border-radius: 15px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        padding: 0;
        margin-bottom: 10%;
        min-height: auto;
    }
    
    .quiz-container {
        flex: 1;
        overflow-y: auto;
        padding: 15px;
        background-color: #f9f9f9;
        border-bottom: 1px solid #ddd;
        margin: 0;
        max-width: 100%;
        border-radius: 10px 10px 0 0;
        box-shadow: none;
        transform: none !important;
    }
    
    .quiz-container:hover {
        transform: none !important;
        box-shadow: none;
    }
    
    .quiz-heading {
        font-size: 1.4rem;
        margin-bottom: 15px;
    }
    
    .quiz-question {
        font-size: 1.1rem;
        margin-bottom: 15px;
        line-height: 1.3;
    }
    
    .quiz-question-container {
    padding: 15px;
        margin-bottom: 20px;
        border-radius: 12px;
    }
    
    .choice-item {
        padding: 12px;
        font-size: 0.95rem;
        margin-bottom: 8px;
    border-radius: 8px;
        transition: transform 0.15s ease, background-color 0.2s ease;
    }
    
    .choice-item:active {
        transform: scale(0.98);
    }
    
    .question-image, .choice-image {
        max-width: 100%;
        max-height: 180px;
        object-fit: contain;
        margin: 10px auto;
    }
    
    .choice-image {
        max-width: 70%;
        max-height: 120px;
        margin: 0.5rem auto;
    }
    
    .connected-users-container {
        padding: 15px;
        margin-top: 15px;
        border-top: 1px solid #eee;
        flex: initial;
    }
    
    .user-cards {
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
        gap: 8px;
    }
    
    .user-card {
        padding: 10px 8px;
    border-radius: 10px;
    }
    
    .user-card-username {
        font-size: 0.85rem;
        margin-bottom: 4px;
        white-space: nowrap;
    overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .chat-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        max-height: 70vh;
        margin-top: 0;
        border-radius: 15px 15px 0 0;
        box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
        animation: slideUp 0.3s ease-out;
        z-index: 1100;
    }
    
    @keyframes slideUp {
        0% {
            opacity: 0;
            transform: translateY(20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    
    .chat-wrapper::before {
        content: '';
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        width: 40px;
        height: 5px;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        z-index: 10;
    }
    
    .outer-quiz-container {
        padding-bottom: 60px;
    }
    
    .music-toggle {
        position: fixed;
        bottom: 70px;
        right: 15px;
        width: 36px;
        height: 36px;
        z-index: 1000;
    }
    
    .music-toggle img {
        width: 20px;
        height: 20px;
    }
    
    /* Chat system to match user's design */
    .floating-chat {
        position: relative;
        width: 100%;
        max-height: 300px;
        background-color: white;
        border-top: 1px solid #ddd;
    display: flex;
    flex-direction: column;
        overflow-y: auto;
        z-index: 90;
  }
  
  .floating-chat.closed {
    display: none;
  }
  
  .floating-chat .chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .floating-chat .chat-messages .message-bubble {
    margin-bottom: 10px;
    padding: 8px 12px;
    border-radius: 15px;
    max-width: 80%;
    word-wrap: break-word;
  }
  
  .floating-chat .chat-messages .message-bubble.sent {
    background-color: #3498db;
    color: white;
    align-self: flex-end;
  }
  
  .floating-chat .chat-messages .message-bubble.received {
    background-color: #eaeaea;
    align-self: flex-start;
  }
  
  .floating-chat .chat-input {
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: white;
  }
  
  .floating-chat .chat-input textarea {
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 8px;
    resize: none;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
    /* Mini chat styling */
.mini-chat .chat-header {
    font-size: 14px;
    font-weight: bold;
    color: white;
    background-color: #3498db;
    padding: 5px;
    text-align: center;
        position: relative;
    }
    
    .mini-chat .chat-messages {
        flex: 1 1;
        padding: 10px;
        overflow-y: auto;
        background-color: #f9f9f9;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    /* AI Button Container */
.AI-button {
    display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        max-width: 100%;
        width: 100%;
    background-color: white;
    border: 1px solid #ddd;
        border-radius: 10px 10px 0 0;
        box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
        margin: 0;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
    }
    
    .AI-button-child1 {
        flex: 1;
    }
    
    .AI-button-child2 {
        flex: 1;
    }
    
    /* Chat Toggle Button */
 .toggle-chat-button {
        width: 100%;
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
        border-radius: 5px 5px 0 0;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
        -webkit-tap-highlight-color: transparent;
}

    .toggle-chat-button:hover, .toggle-chat-button:active {
    background-color: #2978b5;
    }
    
    /* Buttons */
    .submit-answer-button, .restart-quiz-button {
        font-size: 1rem;
        padding: 12px;
        width: 100%;
        -webkit-tap-highlight-color: transparent;
    }
    
    .submit-answer-button:active, .restart-quiz-button:active {
        transform: scale(0.98);
    }
    
    /* Short Answer Input Styles */
    .short-answer-form {
        width: 100%;
        margin: 15px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .short-answer-input {
        width: 100%;
        padding: 12px;
        font-size: 1rem;
        border: 2px solid #ddd;
        border-radius: 8px;
    background-color: #f9f9f9;
        transition: all 0.3s ease;
        margin-bottom: 15px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        -webkit-appearance: none;
        appearance: none;
    }
    
    .short-answer-input:focus {
        border-color: #5c6bc0;
        box-shadow: 0 0 8px rgba(92, 107, 192, 0.4);
        outline: none;
    }
    
    /* Quiz Over Styling */
    .quiz-over-container {
        padding: 20px 15px;
    }
    
    .quiz-over h2 {
        font-size: 1.8rem;
        margin-bottom: 15px;
    }
    
    .quiz-summary {
        font-size: 1rem;
        margin-bottom: 20px;
    }
    
    .ranking-list {
        margin: 15px 0;
        max-height: 50vh;
        overflow-y: auto;
    }
    
    .ranking-item {
    display: flex;
        justify-content: space-between;
        background: #f4f4f4;
        padding: 10px 12px;
        border-radius: 8px;
        margin: 6px 0;
    }
    
    .rank-number {
        font-size: 1rem;
        font-weight: bold;
        color: #333;
    }
    
    .player-name {
        font-size: 1rem;
        color: #555;
        max-width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
    .player-score {
        font-size: 1rem;
        font-weight: bold;
        color: #222;
    }
    
    .restart-quiz-button {
        background: #4CAF50;
        color: white;
        padding: 12px 15px;
        border: none;
        border-radius: 8px;
        font-size: 1rem;
        cursor: pointer;
        transition: all 0.3s ease;
        width: 100%;
        margin-top: 15px;
    }
    
    /* Feedback container */
    .feedback-container {
        margin-top: 15px;
        background-color: #f9f9f9;
        padding: 12px;
        border-radius: 8px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    }
    
    .feedback-message {
        margin-bottom: 8px;
        padding: 8px;
        border-left: 4px solid #3498db;
        background-color: #ffffff;
        border-radius: 4px;
        font-size: 0.9rem;
    }
    
    /* Fix keyboard issues */
    .outer-quiz-container.keyboard-open {
        padding-bottom: 250px; /* Extra space when keyboard is open */
    }
    
    /* Instructional container */
.instructional-container {
        padding: 12px;
        background-color: #ffffff;
        border-radius: 8px;
        margin-bottom: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .instructional-container h2 {
        font-size: 1.2rem;
    color: #333333;
        margin-bottom: 8px;
  }
  
  .instructional-container p {
        font-size: 0.9rem;
    color: #666666;
        line-height: 1.4;
  }
  
  .make-test-button {
    background-color: #3498db;
    color: white;
        padding: 8px 15px;
        font-size: 0.95rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
        width: 100%;
        margin-top: 10px;
    }
}

/* Optimize AI button on very small phones */
@media (max-width: 480px) {
    .quiz-container {
        padding: 12px;
    }
    
    .quiz-heading {
        font-size: 1.25rem;
    }
    
    .quiz-question {
        font-size: 1rem;
    }
    
    .choice-item {
        padding: 10px;
        font-size: 0.9rem;
    }
    
    .user-cards {
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    }
    
    .countdown {
        font-size: 2rem;
        width: 80px;
        height: 80px;
    }
    
    .chat-wrapper {
        max-height: 80vh;
    }
    
    .AI-button {
        padding: 5px;
    }
    
    .toggle-chat-button {
        padding: 8px;
        font-size: 14px;
    }
}

/* Quiz Progress Bar */
.quiz-progress-container {
    margin-bottom: 25px;
    width: 100%;
    position: relative;
}

.quiz-progress-bar {
    height: 8px;
    background: rgba(106, 17, 203, 0.1);
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    margin-bottom: 8px;
}

.quiz-progress-fill {
    height: 100%;
    background: linear-gradient(90deg, #6a11cb, #2575fc);
    border-radius: 20px;
    transition: width 0.5s ease-in-out;
}

.quiz-progress-text {
    font-size: 0.9rem;
    color: #666;
    text-align: right;
    font-weight: 500;
}

/* Active Quiz Container */
.quiz-active-container {
    position: relative;
}

/* Question Image */
.question-image-container {
    margin: 20px 0;
    text-align: center;
}

.question-image {
    max-width: 90%;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    border: 2px solid rgba(255, 255, 255, 0.5);
}

/* Choice options styling */
.choices-container {
    margin-bottom: 25px;
}

.choice-text {
    flex: 1;
    margin-left: 15px;
}

.choice-label {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.choice-item.selected {
    background: rgba(106, 17, 203, 0.1);
    border-color: #6a11cb;
    box-shadow: 0 5px 15px rgba(106, 17, 203, 0.15);
    transform: translateY(-2px);
}

.mcqa-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 16px;
}

/* Icons styling */
.section-icon, 
.waiting-icon, 
.countdown-icon,
.submit-icon,
.check-icon,
.user-icon {
    margin-right: 10px;
    vertical-align: middle;
}

.section-icon {
    color: #6a11cb;
    font-size: 1.2em;
}

.waiting-icon {
    font-size: 2.5rem;
    color: #F09819;
    margin-bottom: 15px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.countdown-icon {
    color: #FF512F;
    font-size: 1.5rem;
    animation: pulse 1s infinite alternate;
}

.user-icon {
    font-size: 1.8rem;
    color: #6a11cb;
    margin-bottom: 10px;
}

.check-icon {
    color: #4caf50;
}

.submit-icon {
    margin-right: 8px;
}

/* Countdown container */
.countdown-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

/* Rankings styling */
.rankings-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0 20px;
    font-size: 1.6rem;
    color: #2c3e50;
}

.trophy-icon {
    color: #F09819;
    margin-right: 10px;
    font-size: 1.8rem;
}

.ranking-item {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    margin-bottom: 12px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
}

.ranking-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.ranking-item .rank {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    font-weight: bold;
    font-size: 1.2rem;
}

.ranking-item .player-name {
    flex: 1;
    font-size: 1.2rem;
    font-weight: 500;
    color: #2c3e50;
}

.ranking-item .player-score {
    font-size: 1.5rem;
    font-weight: 700;
    color: #6a11cb;
    padding-left: 15px;
    border-left: 2px solid rgba(0, 0, 0, 0.05);
}

.rank-icon {
    font-size: 1.8rem;
}

.rank-icon.gold {
    color: #FFC107;
}

.rank-icon.silver {
    color: #B0BEC5;
}

.rank-icon.bronze {
    color: #CD7F32;
}

.top-1 {
    background: linear-gradient(to right, rgba(255, 193, 7, 0.1), white);
    border-left: 5px solid #FFC107;
}

.top-2 {
    background: linear-gradient(to right, rgba(176, 190, 197, 0.1), white);
    border-left: 5px solid #B0BEC5;
}

.top-3 {
    background: linear-gradient(to right, rgba(205, 127, 50, 0.1), white);
    border-left: 5px solid #CD7F32;
}

/* Final score styling */
.final-score {
    font-size: 2.5rem;
    font-weight: 700;
    color: #6a11cb;
    background: linear-gradient(90deg, #6a11cb, #2575fc);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    padding: 0 5px;
}

/* Waiting message styling */
.waiting-message {
    text-align: center;
    padding: 40px 20px;
    background: white;
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
    margin: 20px 0;
}

.waiting-message h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #2c3e50;
}

.waiting-message p {
    color: #666;
    font-size: 1.1rem;
    max-width: 80%;
    margin: 0 auto;
}

/* Loading spinner */
.loading-spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 4px solid rgba(106, 17, 203, 0.1);
    border-radius: 50%;
    border-top-color: #6a11cb;
    animation: spin 1s ease-in-out infinite;
    margin-bottom: 15px;
}

/* Submission indicator */
.submission-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-size: 0.9rem;
    color: #4caf50;
    font-weight: 500;
    background: rgba(76, 175, 80, 0.1);
    padding: 5px 10px;
    border-radius: 20px;
}

/* Quiz over container */
.quiz-over-container {
    padding: 30px;
    text-align: center;
}

.quiz-over h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #6a11cb;
    margin-bottom: 20px;
    background: linear-gradient(90deg, #6a11cb, #2575fc);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.quiz-summary {
    font-size: 1.3rem;
    color: #555;
    margin-bottom: 30px;
}

/* Music toggle */
.music-toggle {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 5;
}

.music-toggle:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.music-toggle img {
    width: 30px;
    height: 30px;
}

/* Responsive for tablet */
@media (max-width: 992px) {
    .mcqa-grid {
        grid-template-columns: 1fr;
    }
    
    .ranking-item .player-score {
        font-size: 1.3rem;
    }
    
    .final-score {
        font-size: 2.2rem;
    }
    
    .waiting-message h3 {
        font-size: 1.6rem;
    }
}

/* Responsive for mobile */
  @media (max-width: 768px) {
    .quiz-progress-bar {
        height: 6px;
    }
    
    .ranking-item {
        padding: 12px 15px;
    }
    
    .ranking-item .rank {
        width: 30px;
        height: 30px;
        font-size: 1rem;
    }
    
    .ranking-item .player-name {
        font-size: 1rem;
    }
    
    .ranking-item .player-score {
        font-size: 1.1rem;
    }
    
    .rank-icon {
        font-size: 1.4rem;
    }
    
    .waiting-message h3 {
        font-size: 1.4rem;
    }
    
    .waiting-message p {
        font-size: 1rem;
        max-width: 100%;
    }
    
    .final-score {
        font-size: 1.8rem;
    }
    
    .quiz-over h2 {
        font-size: 2rem;
    }
    
    .quiz-summary {
        font-size: 1.1rem;
    }
    
    .music-toggle {
        width: 40px;
        height: 40px;
        bottom: 15px;
        right: 15px;
    }
    
    .music-toggle img {
        width: 24px;
        height: 24px;
    }
}

/* Small mobile devices */
@media (max-width: 480px) {
    .ranking-item .rank {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
    
    .ranking-item .player-score {
        font-size: 1rem;
        padding-left: 10px;
    }
    
    .rank-icon {
        font-size: 1.2rem;
    }
    
    .quiz-over h2 {
        font-size: 1.8rem;
    }
    
    .quiz-summary {
        font-size: 1rem;
    }
    
    .submission-indicator {
        font-size: 0.8rem;
        padding: 3px 8px;
    }
}

/* Chat and Music Icons */
.chat-icon {
    margin-right: 10px;
    font-size: 1.2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    padding: 4px;
    transition: all 0.3s ease;
}

.music-icon {
    font-size: 1.5rem;
    color: #6a11cb;
    transition: all 0.3s ease;
}

.music-icon.muted {
    color: #999;
}

/* Chat Button Styling */
.toggle-chat-button {
    background: linear-gradient(45deg, #6a11cb 0%, #2575fc 100%);
    color: white;
    border: none;
    border-radius: 12px;
    padding: 15px 20px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 8px 15px rgba(37, 117, 252, 0.2);
      display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
    letter-spacing: 0.5px;
}

.toggle-chat-button:hover {
    background: linear-gradient(45deg, #5515a5 0%, #2061d6 100%);
    box-shadow: 0 12px 20px rgba(37, 117, 252, 0.3);
    transform: translateY(-2px);
}

.toggle-chat-button:active {
    transform: translateY(1px);
    box-shadow: 0 5px 10px rgba(37, 117, 252, 0.2);
}

/* AI Button container */
.AI-button {
    margin: 30px 0;
    max-width: 500px;
    width: 100%;
      display: flex;
      flex-direction: column;
    align-items: center;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.95);
    position: relative;
    z-index: 100;
    overflow: visible;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.12);
    padding: 20px;
    transition: all 0.3s ease;
}

.AI-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.18);
}

/* Chat Wrapper */
.chat-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
      width: 100%;
    z-index: 1000;
    margin-top: 10px;
    border-radius: 15px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
    animation: slideDown 0.4s ease-out;
    transform-origin: top center;
}

/* Chat Animation */
@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-15px) scale(0.98);
    }
    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

/* Mobile Phone Styles for Chat */
@media (max-width: 576px) {
    .AI-button {
        margin: 20px 0;
        max-width: 100%;
        padding: 15px;
    }
    
    .toggle-chat-button {
        padding: 12px 15px;
        font-size: 1rem;
    }
    
    .chat-icon {
        width: 20px;
        height: 20px;
        font-size: 1rem;
    }
    
    .chat-wrapper {
        position: fixed;
        top: auto;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        margin-top: 0;
        border-radius: 15px 15px 0 0;
        box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
        animation: slideUp 0.3s ease-out;
        transform-origin: bottom center;
        z-index: 1100;
    }
    
    .chat-wrapper::before {
        content: '';
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        width: 40px;
        height: 5px;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        z-index: 10;
    }
    
    @keyframes slideUp {
        0% {
            opacity: 0;
            transform: translateY(20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

/* Enhanced Mobile Styles for LiveQuizPage */
@media (max-width: 576px) {
    /* Overall layout adjustments */
    .outer-quiz-container {
      padding: 10px;
        min-height: calc(100vh - 20px);
    }
    
    .quiz-container {
        padding: 15px;
        max-width: 100%;
        border-radius: 15px;
        margin: 0;
    }
    
    /* Heading and text adjustments */
    .quiz-heading {
        font-size: 1.4rem;
        margin-bottom: 15px;
    }
    
    .quiz-question {
        font-size: 1.1rem;
        margin-bottom: 15px;
        line-height: 1.3;
    }
    
    /* Quiz question container */
    .quiz-question-container {
        padding: 15px;
        margin-bottom: 20px;
        border-radius: 12px;
    }
    
    /* Choice items */
    .choice-item {
        padding: 10px 12px;
        font-size: 0.9rem;
        margin-bottom: 8px;
        border-radius: 8px;
        transition: transform 0.15s ease, background-color 0.2s ease;
    }
    
    .choice-item:active {
        transform: scale(0.98);
        background-color: rgba(106, 17, 203, 0.05);
    }
    
    /* Submit button */
    .submit-answer-button {
        padding: 12px;
        font-size: 0.95rem;
        margin-top: 20px;
    }
    
    /* Connected users section */
    .connected-users-container {
        padding: 15px;
        margin-top: 20px;
    }
    
    .connected-users-container h5 {
        font-size: 1rem;
        margin-bottom: 10px;
    }
    
    /* User cards */
    .user-cards {
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
        gap: 8px;
    }
    
    .user-card {
        padding: 10px 8px;
        border-radius: 10px;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }
    
    .user-card.submitted {
        background: linear-gradient(to right, rgba(76, 175, 80, 0.1), white);
        border-left: 3px solid #4caf50;
        animation: pulse-green 1.5s infinite;
    }
    
    @keyframes pulse-green {
        0% { box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.4); }
        70% { box-shadow: 0 0 0 6px rgba(76, 175, 80, 0); }
        100% { box-shadow: 0 0 0 0 rgba(76, 175, 80, 0); }
    }
    
    .user-card-username {
        font-size: 0.85rem;
        margin-bottom: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .user-score, .user-card-score {
        font-size: 1rem;
    }
    
    .submission-indicator {
        font-size: 0.65rem;
        padding: 2px 5px;
    }
    
    /* Countdown styles */
    .countdown {
        font-size: 1.6rem;
        padding: 5px 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2000;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 50%;
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    }
    
    /* AI Chat button improvements */
    .AI-button {
        margin: 15px 0;
        padding: 12px;
        max-width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        border-radius: 15px 15px 0 0;
        box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.1);
        background: white;
    }
  
    .toggle-chat-button {
        padding: 10px;
        font-size: 0.9rem;
        border-radius: 8px;
    }
    
    /* Images and media */
    .question-image, .choice-image {
        max-width: 100%;
        max-height: 180px;
        object-fit: contain;
        margin: 10px 0;
    }
    
    /* Music toggle button */
    .music-toggle {
        width: 36px;
        height: 36px;
        bottom: 70px;
        right: 10px;
    }
    
    .music-toggle img {
        width: 20px;
        height: 20px;
    }
    
    /* Quiz over screen */
    .quiz-over-container {
        padding: 20px 15px;
    }
    
    .quiz-over h2 {
        font-size: 1.5rem;
    }
    
    .quiz-summary {
        font-size: 1rem;
    }
    
    .ranking-item {
        padding: 10px;
        margin-bottom: 8px;
    }
    
    .ranking-item .rank-number {
        width: 24px;
        font-size: 0.9rem;
    }
    
    .ranking-item .player-name {
        font-size: 0.9rem;
    }
    
    .ranking-item .player-score {
        font-size: 0.9rem;
    }
    
    /* Short answer input */
    .short-answer-input {
        padding: 12px;
        font-size: 0.9rem;
    }
    
    /* Start game button */
    .start-game-button {
        padding: 12px;
        font-size: 1rem;
        width: 100%;
    }
    
    /* Fix for quiz container height on mobile */
    .quiz-active-container {
        max-height: calc(100vh - 150px);
        overflow-y: auto;
    }
    
    /* Optimize buttons for touch */
    .submit-answer-button, .toggle-chat-button, .start-game-button {
        -webkit-tap-highlight-color: transparent;
        transition: transform 0.2s ease, box-shadow 0.2s ease;
    }
    
    .submit-answer-button:active, .toggle-chat-button:active, .start-game-button:active {
        transform: scale(0.98);
    }
    
    /* Add back the input styles */
    .choice-item input[type="checkbox"],
    .choice-item input[type="radio"] {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }
    
    /* Improve loading/waiting states for mobile */
    .loading, .waiting-message, .error {
        font-size: 1.1rem;
        padding: 20px 15px;
        border-radius: 12px;
        margin: 20px 0;
    }
    
    .loading::after {
        width: 24px;
        height: 24px;
        border-width: 2px;
        margin: 15px auto 0;
    }
    
    /* Fix form inputs for mobile keyboards */
    .short-answer-input {
        padding: 12px;
        font-size: 0.9rem;
        border-radius: 8px;
        -webkit-appearance: none;
        appearance: none;
    }
    
    .short-answer-input:focus {
        position: relative;
        z-index: 5;
    }
    
    /* Handle mobile keyboard appearance */
    @supports (padding: env(keyboard-inset-height, 0px)) {
        .short-answer-form {
            padding-bottom: env(keyboard-inset-height, 0px);
        }
    }
    
    /* Better loading spinner for mobile */
    .loading-spinner {
        width: 40px;
        height: 40px;
        border-width: 3px;
        margin-bottom: 12px;
    }
    
    /* Mobile quiz over state */
    .restart-quiz-button {
        width: 100%;
        padding: 14px;
        font-size: 1rem;
        margin-top: 20px;
    }
    
    .ranking-list {
        max-height: 60vh;
        overflow-y: auto;
        padding: 5px;
        margin: 15px 0;
    }
}

/* Mobile animations */
@media (max-width: 576px) {
    @keyframes mobileHighlight {
        0% { transform: translateX(0); }
        2% { transform: translateX(-3px); }
        4% { transform: translateX(3px); }
        6% { transform: translateX(-3px); }
        8% { transform: translateX(3px); }
        10% { transform: translateX(0); }
    }
    
    .correct-answer {
        animation: mobileHighlight 1s ease, highlight-correct 1s ease;
    }
    
    .wrong-answer {
        animation: mobileHighlight 1s ease, highlight-wrong 1s ease;
    }
}

/* Add touch feedback for iOS devices */
@supports (-webkit-touch-callout: none) {
    .choice-item:active, .submit-answer-button:active, .toggle-chat-button:active {
        opacity: 0.7;
    }
    
    /* iOS-specific chat behavior */
    .chat-wrapper {
        -webkit-overflow-scrolling: touch;
    }
}

/* iPhone-specific fixes */
@media screen and (max-width: 390px) {
    .quiz-container {
        padding: 12px;
    }
    
    .user-cards {
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    }
    
    .user-card-username {
        font-size: 0.8rem;
    }
    
    .choice-item {
        padding: 8px 10px;
    }
    
    /* Ensure content is visible above the fixed AI button */
    .outer-quiz-container {
        padding-bottom: 80px;
    }
    
    /* Fix for small iPhone screens */
    .chat-wrapper {
        max-height: 60vh;
    }
}

/* Fix for notches and home indicators on modern phones */
@supports (padding: max(0px)) {
    .AI-button {
        padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
    
    .outer-quiz-container {
        padding-bottom: max(80px, calc(80px + env(safe-area-inset-bottom)));
    }
}

/* Landscape mode optimizations for mobile */
@media (max-width: 896px) and (orientation: landscape) {
    .outer-quiz-container {
        padding: 10px;
        min-height: 100vh;
    }
    
    .quiz-container {
        padding: 12px;
        max-height: 85vh;
        overflow-y: auto;
    }
    
    /* Two-column layout for choices in landscape */
    .choices-list:not(.short-answer-form) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
    }
    
    .user-cards {
        grid-template-columns: repeat(4, minmax(80px, 1fr));
    }
    
    /* Adjust AI button for landscape */
    .AI-button {
        position: fixed;
        right: 10px;
        bottom: 10px;
        left: auto;
        width: auto;
        max-width: 200px;
        border-radius: 12px;
    }
    
    /* Chat layout for landscape */
    .chat-wrapper {
        width: 50%;
        max-width: 350px;
        right: 10px;
        left: auto;
        height: 70vh;
    }
    
    /* Centered countdown in landscape */
    .countdown {
        font-size: 2rem;
        width: 120px;
        height: 120px;
    }
}

  
  