.chat-container{
    display:flex;
    flex-direction: row; 
    height:200vh;
}

.sidebar {
   flex: 1;
   background-color:teal; 
   color: #ffffff;
   padding: 20px;
   overflow-y: auto;
}

.chat-area {
    flex:3;
    display:flex;
    flex-direction: column;
}

.chat-header{
    background-color: #3498db;
    color: #ffffff;
    padding: 15px;
    text-align: center;
}

.messages{
    flex:1;
    padding:20px;
    overflow-y: auto;
}

.message{
    margin-bottom: 15px;
}

.sent{
    display:flex;
    justify-content: flex-end;
}

.received{
    display:flex;
    justify-content: flex-start;
}

.message-bubble{
    max-width: 70%;
    padding: 10px;
    border-radius: 10px;
}

.sent .message-bubble{
    background-color: #3498bd;
    color: #ffffff;
    border-bottom-right-radius: 0;
}

.received .message-bubble{
    background-color: #eaeaea;
    border-top-right-radius: 0;
}

.message-input{
    background-color: #ffffff;
    padding:15px;
    border-top: 1px solid #e0e0e0;
    display:flex;
    align-items: center;

}

.message-input textarea{
    flex: 1;
    border: none;
    border-radius: 5px;
    padding: 10px;
    resize: none;

}

.message-input button{
    background-color: #3498db;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    padding: 10px, 20px;
    margin-top:20px;
}

/* Friends list chat integration */
.friends-chat-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    max-width: 1200px;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.friends-column {
    flex: 1;
    background-color: #f5f5f5;
    border-right: 1px solid #ddd;
    overflow-y: auto;
    max-width: 300px;
}

.chat-column {
    flex: 2;
    display: flex;
    flex-direction: column;
}

.chat-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #f9f9f9;
    color: #666;
}

.friend-item {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    transition: background-color 0.2s;
}

.friend-item:hover, .friend-item.active {
    background-color: #e0f7fa;
}

.friend-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
    background-color: #ddd;
}

.friend-details {
    display: flex;
    flex-direction: column;
}

.friend-name {
    font-weight: bold;
    color: #333;
}

.online-status {
    display: flex;
    align-items: center;
    font-size: 0.8em;
    color: #666;
}

.status-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
}

.status-dot.online {
    background-color: #4caf50;
}

.status-dot.offline {
    background-color: #9e9e9e;
}

.chat-active-header {
    padding: 15px;
    background-color: #3498db;
    color: white;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.chat-active-header .friend-avatar {
    margin-right: 10px;
}

.no-access {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px;
    text-align: center;
    color: #666;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
    .friends-chat-container {
        flex-direction: column;
        height: auto;
    }
    
    .friends-column {
        max-width: 100%;
        border-right: none;
        border-bottom: 1px solid #ddd;
    }
}

