/* Arcade.css */
.outer-lab-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.lab-container {
  background-color: #f8fafc;
  border-radius: 16px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 2rem;
  margin-top: 2rem;
}

.lab-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #2c5282;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
}

.lab-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: linear-gradient(to right, #4299e1, #805ad5);
  border-radius: 2px;
}

.lab-sections {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 2rem;
}

.lab-section {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.lab-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.lab-section h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2c5282;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #edf2f7;
}

.lab-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.lab-link {
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.lab-link:last-child {
  margin-bottom: 0;
}

.lab-link a {
  display: block;
  padding: 1rem;
  background-color: #f7fafc;
  border-radius: 8px;
  text-decoration: none;
  color: #2d3748;
  font-weight: 500;
  transition: all 0.3s ease;
  border: 1px solid #edf2f7;
}

.lab-link:hover a {
  background-color: #ebf8ff;
  color: #2b6cb0;
  transform: translateX(5px);
  border-color: #bee3f8;
}

.lab-link a img {
  display: block;
  margin: 0 auto 1rem;
  border-radius: 8px;
  transition: transform 0.3s ease;
  max-width: 100%;
  height: auto;
}

.lab-link:hover a img {
  transform: scale(1.03);
}

@media (max-width: 768px) {
  .lab-sections {
    grid-template-columns: 1fr;
  }
  
  .lab-container {
    padding: 1.5rem;
  }
  
  .lab-title {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .outer-lab-container {
    padding: 1rem;
  }
  
  .lab-container {
    padding: 1rem;
  }
  
  .lab-title {
    font-size: 1.8rem;
  }
  
  .lab-section h2 {
    font-size: 1.3rem;
  }
} 