/* Enhanced Math Keyboard Styles */

.math-keyboard {
    --key-size: 48px;
    --key-gap: 8px;
    --key-radius: 6px;
    
    display: flex;
    flex-direction: column;
    gap: var(--key-gap);
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    transition: background-color 0.3s, box-shadow 0.3s;
}

/* Keyboard themes */
.math-keyboard.light {
    --key-bg: #f8f9fa;
    --key-text: #333333;
    --key-border: #d1d5db;
    --key-hover-bg: #e9ecef;
    --key-active-bg: #dee2e6;
    --fn-key-bg: #e9ecef;
    --fn-key-text: #2563eb;
    --del-key-bg: #fee2e2;
    --del-key-text: #dc2626;
}

.math-keyboard.dark {
    --key-bg: #2d3748;
    --key-text: #e2e8f0;
    --key-border: #4a5568;
    --key-hover-bg: #4a5568;
    --key-active-bg: #2d3748;
    --fn-key-bg: #2d3748;
    --fn-key-text: #60a5fa;
    --del-key-bg: #7f1d1d;
    --del-key-text: #fecaca;
}

.keyboard-row {
    display: flex;
    gap: var(--key-gap);
    justify-content: center;
}

.key {
    width: var(--key-size);
    height: var(--key-size);
    border: 1px solid var(--key-border);
    border-radius: var(--key-radius);
    background-color: var(--key-bg);
    color: var(--key-text);
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    user-select: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.key:hover {
    background-color: var(--key-hover-bg);
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.key:active {
    background-color: var(--key-active-bg);
    transform: translateY(1px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Special keys */
.key:nth-child(4),  /* Operators */
.key:nth-child(5) {  
    background-color: var(--fn-key-bg);
    color: var(--fn-key-text);
    font-weight: bold;
}

/* Delete key */
.key-del {
    background-color: var(--del-key-bg);
    color: var(--del-key-text);
    font-size: 14px;
    font-weight: bold;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .math-keyboard {
        --key-size: 40px;
        --key-gap: 6px;
    }
    
    .key {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .math-keyboard {
        --key-size: 36px;
        --key-gap: 4px;
    }
    
    .key {
        font-size: 12px;
    }
    
    .key-del {
        font-size: 10px;
    }
}

