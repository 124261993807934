/* Founder Letter - Main Container */
.founder-letter-container {
    font-family: 'Poppins', sans-serif;
    color: #333;
    overflow-x: hidden;
    background-color: #f8f9fa;
  }
  
  /* Header Section with Background Image */
  .founder-letter-header {
    position: relative;
    height: 50vh;
    min-height: 300px;
    background-image: url('https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
  }
  
  .founder-letter-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
    z-index: 1;
  }
  
  .founder-letter-header h1 {
    position: relative;
    z-index: 2;
    color: white;
    font-weight: 700;
    font-size: 3.5rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    animation: fadeInUp 1s ease-out;
  }
  
  .founder-letter-header .highlight {
    color: #4CAF50;
  }
  
  /* Letter Content */
  .founder-letter-content {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease, transform 1s ease;
    padding: 2rem 1rem;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    margin-bottom: 3rem;
  }
  
  .founder-letter-content.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .founder-signature {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    opacity: 0;
    animation: fadeIn 1s ease-out forwards;
    animation-delay: 0.5s;
  }
  
  .founder-signature h2 {
    font-weight: 600;
    font-size: 1.8rem;
    margin: 0 1.5rem;
    color: #333;
    white-space: nowrap;
  }
  
  .founder-signature .line {
    flex-grow: 1;
    height: 1px;
    background-color: #e0e0e0;
  }
  
  .founder-text {
    font-size: 1.1rem;
    line-height: 1.8;
    opacity: 0;
    animation: fadeIn 1s ease-out forwards;
    animation-delay: 0.8s;
  }
  
  .founder-text .greeting {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
  
  .founder-text p {
    margin-bottom: 1.5rem;
    transition: transform 0.3s ease;
  }
  
  .founder-text p:hover {
    transform: translateX(5px);
  }
  
  /* Scientific Method Box */
  .scientific-method-box {
    background: linear-gradient(135deg, #f5f7fa 0%, #e4e9f0 100%);
    border-radius: 10px;
    padding: 2rem;
    margin: 2.5rem 0;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    border-left: 5px solid #4CAF50;
    transition: transform 0.3s ease;
  }
  
  .scientific-method-box:hover {
    transform: translateY(-5px);
  }
  
  .scientific-method-box h3 {
    color: #2E7D32;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 600;
    font-size: 1.5rem;
  }
  
  .method-steps {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .method-steps {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  .method-step {
    display: flex;
    gap: 1rem;
    background-color: white;
    padding: 1.2rem;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
  }
  
  .method-step:hover {
    transform: scale(1.02);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .step-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #4CAF50;
    color: white;
    border-radius: 50%;
    font-weight: bold;
    font-size: 1.2rem;
    flex-shrink: 0;
  }
  
  .step-content h4 {
    margin: 0 0 0.5rem;
    color: #333;
    font-weight: 600;
    font-size: 1.2rem;
  }
  
  .step-content p {
    margin: 0;
    color: #666;
    line-height: 1.5;
    font-size: 0.95rem;
  }
  
  /* Signature Section */
  .signature {
    margin-top: 3rem;
    text-align: right;
  }
  
  .signature-name {
    font-family: 'Pacifico', cursive;
    font-size: 2rem;
    color: #2E7D32;
    margin-bottom: 0.2rem;
  }
  
  .founder-title {
    font-size: 1rem;
    color: #666;
  }
  
  .closing {
    font-style: italic;
    color: #555;
  }
  
  /* CTA Section */
  .founder-letter-cta {
    padding: 3rem 0;
    background-color: #f0f4f0;
  }
  
  .cta-card {
    background: linear-gradient(135deg, #ffffff 0%, #f9f9f9 100%);
    border: none;
    border-radius: 12px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .cta-card:hover {
    transform: translateY(-5px);
  }
  
  .cta-card h3 {
    color: #333;
    margin-bottom: 1rem;
    font-weight: 600;
  }
  
  .cta-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1.5rem;
    flex-wrap: wrap;
  }
  
  .cta-button {
    display: inline-block;
    padding: 0.8rem 1.5rem;
    border-radius: 50px;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .cta-button.primary {
    background-color: #4CAF50;
    color: white;
  }
  
  .cta-button.secondary {
    background-color: transparent;
    color: #4CAF50;
    border: 2px solid #4CAF50;
  }
  
  .cta-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .cta-button.primary:hover {
    background-color: #2E7D32;
  }
  
  .cta-button.secondary:hover {
    background-color: rgba(76, 175, 80, 0.1);
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .founder-letter-header h1 {
      font-size: 2.5rem;
    }
    
    .founder-signature h2 {
      font-size: 1.5rem;
    }
    
    .founder-text {
      font-size: 1rem;
    }
    
    .scientific-method-box {
      padding: 1.5rem;
    }
    
    .method-step {
      padding: 1rem;
    }
    
    .signature-name {
      font-size: 1.8rem;
    }
  }
  
  @media (max-width: 576px) {
    .founder-letter-header h1 {
      font-size: 2rem;
    }
    
    .founder-signature {
      flex-direction: column;
      gap: 1rem;
    }
    
    .founder-signature .line {
      width: 50%;
    }
    
    .method-step {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .cta-buttons {
      flex-direction: column;
    }
  } 