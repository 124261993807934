/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #3b82f6;
  --primary-light: #60a5fa;
  --primary-dark: #2563eb;
  --secondary-color: #8b5cf6;
  --accent-color: #10b981;
  --text-dark: #1e293b;
  --text-medium: #64748b;
  --text-light: #f8fafc;
  --bg-light: #f8fafc;
  --bg-lighter: #ffffff;
  --bg-card: #ffffff;
  --bg-gradient: linear-gradient(135deg, #3b82f6 0%, #8b5cf6 100%);
  --success: #10b981;
  --warning: #f59e0b;
  --danger: #ef4444;
  --border-radius-sm: 8px;
  --border-radius-md: 12px;
  --border-radius-lg: 20px;
  --border-radius-xl: 30px;
  --box-shadow-sm: 0 4px 6px rgba(0, 0, 0, 0.05);
  --box-shadow-md: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --box-shadow-lg: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

/* Profile Page Container */
.profile-page-container {
  display: flex;
  gap: 30px;
  padding: 40px;
  max-width: 1200px;
  margin: 40px auto;
  background-color: var(--bg-light);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow-lg);
  font-family: 'Poppins', sans-serif;
}

/* Profile Container with modern styling */
.profile-container {
  flex: 1;
  background-color: var(--bg-card);
  border-radius: var(--border-radius-md);
  box-shadow: var(--box-shadow-md);
  overflow: hidden;
  position: relative;
}

/* Profile Header with gradient background */
.profile-header {
  background: var(--bg-gradient);
  padding: 40px 20px;
  text-align: center;
  position: relative;
}

.profile-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  background: var(--bg-card);
  border-radius: 50% 50% 0 0;
}

/* Profile Picture Styling */
.profile-pic-placeholder {
  width: 150px;
  height: 150px;
  margin: 0 auto 20px;
  background-color: var(--bg-lighter);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 4px solid var(--bg-lighter);
  box-shadow: var(--box-shadow-md);
  position: relative;
  z-index: 10;
}

.profile-pic-placeholder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-pic-placeholder::before {
  content: '';
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  background: var(--bg-gradient);
  z-index: -1;
  border-radius: 50%;
  opacity: 0.7;
}

/* Profile Info Container */
.profile-info {
  padding: 30px;
  text-align: center;
}

/* Username and Info Styling */
.username-display {
  font-size: 2rem;
  font-weight: 700;
  color: var(--text-dark);
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
}

.username-display::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background: var(--primary-color);
  border-radius: 3px;
}

/* Stats Cards */
.profile-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin: 30px 0;
}

.stat-card {
  background-color: var(--bg-lighter);
  padding: 20px;
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow-sm);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--box-shadow-md);
}

.stat-label {
  font-size: 0.9rem;
  color: var(--text-medium);
  margin-bottom: 8px;
  font-weight: 500;
}

.stat-value {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--text-dark);
}

.profile-email,
.profile-streak,
.profile-questions-answered {
  font-size: 1.1rem;
  color: var(--text-medium);
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-email strong,
.profile-streak strong,
.profile-questions-answered strong {
  color: var(--text-dark);
  margin-bottom: 5px;
  font-weight: 600;
}

/* Button Styling */
.profile-buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
}

.update-button,
.cancel-button {
  font-family: 'Poppins', sans-serif;
  padding: 12px 24px;
  border-radius: var(--border-radius-sm);
  border: none;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  font-size: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.update-button {
  background-color: var(--primary-color);
  box-shadow: 0 4px 6px rgba(59, 130, 246, 0.2);
}

.update-button:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(59, 130, 246, 0.3);
}

.cancel-button {
  background-color: var(--danger);
  box-shadow: 0 4px 6px rgba(239, 68, 68, 0.2);
}

.cancel-button:hover {
  background-color: #dc2626;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(239, 68, 68, 0.3);
}

/* Friends List Styling */
.friends-list-container {
  width: 350px;
  background-color: var(--bg-card);
  border-radius: var(--border-radius-md);
  box-shadow: var(--box-shadow-md);
  overflow: hidden;
}

.friends-list-title {
  background: var(--bg-gradient);
  color: var(--text-light);
  padding: 20px;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 900px) {
  .profile-page-container {
    flex-direction: column;
    padding: 20px;
    margin: 20px;
  }

  .profile-container,
  .friends-list-container {
    width: 100%;
  }
  
  .friends-list-container {
    margin-top: 30px;
  }
}

@media (max-width: 600px) {
  .profile-stats {
    grid-template-columns: 1fr;
  }
  
  .profile-header {
    padding: 30px 20px;
  }
  
  .profile-pic-placeholder {
    width: 120px;
    height: 120px;
  }
  
  .username-display {
    font-size: 1.7rem;
  }
}
