/* InteractiveLabs.css */
/* Modern medical-themed styling for interactive medical learning tools */

:root {
    --primary: #2b6cb0; /* Medical blue */
    --primary-light: #4299e1;
    --primary-dark: #2c5282;
    --secondary: #38a169; /* Medical green */
    --secondary-light: #48bb78;
    --secondary-dark: #276749;
    --accent: #805ad5; /* Purple accent */
    --accent-light: #9f7aea;
    --accent-dark: #6b46c1;
    --text-dark: #2d3748;
    --text-muted: #718096;
    --text-light: #ffffff;
    --background-light: #f7fafc;
    --background-card: #ffffff;
    --gray-100: #f7fafc;
    --gray-200: #edf2f7;
    --gray-300: #e2e8f0;
    --gray-400: #cbd5e0;
    --gray-500: #a0aec0;
    --shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    --card-radius: 12px;
  }
  
  /* Main container */
  .med-labs-container {
    background-color: var(--background-light);
    max-width: 1400px;
    margin: 0 auto;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    color: var(--text-dark);
    padding: 2rem;
  }
  
  /* Header section */
  .med-labs-header {
    margin-bottom: 3rem;
    text-align: center;
  }
  
  .med-labs-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--primary-dark);
    margin-bottom: 1rem;
    position: relative;
    display: inline-block;
  }
  
  .med-labs-title::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 4px;
    background: linear-gradient(to right, var(--primary), var(--accent));
    border-radius: 2px;
  }
  
  .med-labs-subtitle {
    font-size: 1.1rem;
    color: var(--text-muted);
    max-width: 800px;
    margin: 1.5rem auto;
    line-height: 1.6;
  }
  
  /* Filter buttons */
  .med-labs-filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.75rem;
    margin: 2rem 0;
  }
  
  .filter-button {
    background-color: var(--gray-200);
    color: var(--text-dark);
    border: none;
    border-radius: 50px;
    padding: 0.6rem 1.2rem;
    font-size: 0.95rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .filter-button:hover {
    background-color: var(--gray-300);
    transform: translateY(-2px);
  }
  
  .filter-button.active {
    background-color: var(--primary);
    color: var(--text-light);
    box-shadow: 0 4px 8px rgba(43, 108, 176, 0.25);
  }
  
  /* Category sections */
  .med-lab-category {
    margin-bottom: 3.5rem;
  }
  
  .category-header {
    margin-bottom: 2rem;
  }
  
  .category-header h2 {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--primary-dark);
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
  }
  
  .category-header h2::after {
    content: '';
    flex: 1;
    height: 1px;
    background-color: var(--gray-300);
    margin-left: 1rem;
  }
  
  .category-header p {
    color: var(--text-muted);
    font-size: 1rem;
    max-width: 800px;
  }
  
  /* App cards grid */
  .med-apps-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 2rem;
  }
  
  /* App card */
  .med-app-card {
    background-color: var(--background-card);
    border-radius: var(--card-radius);
    overflow: hidden;
    box-shadow: var(--shadow);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid var(--gray-200);
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .med-app-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);
  }
  
  .med-app-image {
    position: relative;
    height: 200px;
    overflow: hidden;
    background-color: var(--gray-100);
    border-bottom: 1px solid var(--gray-200);
  }
  
  .med-app-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  .med-app-card:hover .med-app-image img {
    transform: scale(1.05);
  }
  
  .med-app-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .med-app-content h3 {
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--primary-dark);
    margin-bottom: 0.75rem;
  }
  
  .med-app-description {
    color: var(--text-muted);
    font-size: 0.95rem;
    line-height: 1.6;
    margin-bottom: 1.2rem;
    flex-grow: 1;
  }
  
  .med-app-relevance {
    background-color: var(--gray-100);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-left: 4px solid var(--primary-light);
  }
  
  .relevance-label {
    display: block;
    font-weight: 600;
    color: var(--primary);
    margin-bottom: 0.5rem;
    font-size: 0.85rem;
    text-transform: uppercase;
  }
  
  .med-app-relevance p {
    font-size: 0.9rem;
    color: var(--text-muted);
    margin: 0;
    line-height: 1.5;
  }
  
  .med-app-button {
    background-color: var(--primary);
    color: var(--text-light);
    border: none;
    border-radius: 8px;
    padding: 0.8rem 1.2rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: auto;
  }
  
  .med-app-button:hover {
    background-color: var(--primary-dark);
    transform: translateY(-2px);
  }
  
  /* Responsive design */
  @media (max-width: 1200px) {
    .med-apps-grid {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }
  
  @media (max-width: 768px) {
    .med-labs-container {
      padding: 1.5rem;
    }
    
    .med-labs-title {
      font-size: 2rem;
    }
    
    .med-apps-grid {
      grid-template-columns: 1fr;
    }
    
    .filter-button {
      padding: 0.5rem 1rem;
      font-size: 0.85rem;
    }
    
    .category-header h2 {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .med-labs-container {
      padding: 1rem;
    }
    
    .med-labs-title {
      font-size: 1.8rem;
    }
    
    .med-labs-subtitle {
      font-size: 1rem;
    }
    
    .med-app-content h3 {
      font-size: 1.25rem;
    }
  }
  