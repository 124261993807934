/* 🌟 MAIN CONTAINER 🌟 */
.homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5vh; /* Reduced from 10vh */
    gap: 25px; /* Reduced from 40px */
    margin-bottom: 0%;
    font-family: 'Poppins', sans-serif;
    color: #333;
    background: linear-gradient(135deg, #f5f7fa 0%, #e4e7eb 100%);
}

/* 🌟 GENERAL SECTION STYLES 🌟 */
.fade-in-section {
    width: 90%;
    max-width: 1200px;
    padding: 30px 25px; /* Reduced from 50px 30px */
    border-radius: 12px; /* Reduced from 16px */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.07); /* Reduced shadow */
    opacity: 0;
    transition: opacity 1s ease, transform 1s ease;
    transform: translateY(25px);
    position: relative;
    overflow: hidden;
    margin-bottom: 10px; /* Reduced from 20px */
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: translateY(0);
}

.fade-in-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px; /* Reduced from 4px */
    height: 100%;
    background: linear-gradient(to bottom, #667eea, #764ba2);
    border-radius: 3px 0 0 3px;
}

/* 🌟 VIDEO CONTAINER STYLES 🌟 */
.video-container {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.08);
    transition: transform 0.5s ease;
    margin-top: 15px; /* Added this to reduce space between text and video */
}

.video-container:hover {
    transform: translateY(-3px); /* Reduced from -5px */
}

.section-video {
    width: 100%;
    max-height: 350px; /* Reduced from 420px */
    border-radius: 10px;
    margin-top: 0;
    transition: transform 0.5s ease;
}

.button-homepage {
    background-color: #6a1b9a;
    color: white;
    font-weight: bold;
    padding: 10px 25px; /* Reduced from 12px 30px */
    border-radius: 25px;
    transition: all 0.3s ease;
    border: 2px solid transparent;
    font-size: 1.05em; /* Reduced from 1.1em */
    margin-top: 10px; /* Reduced from 15px */
    box-shadow: 0 4px 10px rgba(106, 27, 154, 0.3);
}

.button-homepage:hover {
    background-color: white;
    color: #6a1b9a;
    border-color: #6a1b9a;
    transform: translateY(-2px); /* Reduced from -3px */
    box-shadow: 0 6px 15px rgba(106, 27, 154, 0.25);
}

/* 🌟 INDIVIDUAL SECTION STYLES 🌟 */
.section-welcome, .section-tools, .section-top-down, .section-real-life, .section-stem, .section-testimonial {
    padding: 35px 25px; /* Reduced from 70px 30px */
    border-radius: 12px;
}

.section-welcome {
    background: linear-gradient(120deg, #f0f9ff 0%, #d6eeff 100%);
    text-align: center;
    color: #01579b;
}

.section-tools {
    background: linear-gradient(120deg, #e3f2fd 0%, #bbdefb 100%);
    color: #01579b;
}

.section-top-down {
    background: linear-gradient(120deg, #e8f5e9 0%, #c8e6c9 100%);
    color: #2e7d32;
}

.section-real-life {
    background: linear-gradient(120deg, #fff3e0 0%, #ffe0b2 100%);
    color: #e65100;
}

.section-stem {
    background: linear-gradient(120deg, #f3e5f5 0%, #e1bee7 100%);
    color: #6a1b9a;
}

.section-testimonial {
    background: linear-gradient(120deg, #f3e5f5 0%, #d1c4e9 100%);
    font-style: italic;
    position: relative;
}

/* 🌟 SECTION HEADINGS STYLES 🌟 */
h1, h2 {
    font-size: 2.2em; /* Reduced from 2.6em */
    font-weight: 800;
    margin-bottom: 15px; /* Reduced from 20px */
    color: #2c3e50;
    position: relative;
    display: inline-block;
}

h2::after {
    content: '';
    position: absolute;
    bottom: -6px; /* Reduced from -8px */
    left: 0;
    width: 50px; /* Reduced from 60px */
    height: 3px; /* Reduced from 4px */
    background: currentColor;
    border-radius: 2px;
}

p {
    font-size: 1.15em; /* Reduced from 1.3em */
    line-height: 1.6; /* Reduced from 1.7 */
    margin-bottom: 20px; /* Reduced from 25px */
    color: #4a4a4a;
}

.section-description {
    max-width: 800px;
    margin: 0 auto 20px; /* Reduced from 30px */
}

/* 🌟 TESTIMONIAL SECTION 🌟 */
.testimonial-intro {
    text-align: center;
    font-size: 1.25em; /* Reduced from 1.4em */
    margin-bottom: 20px; /* Reduced from 30px */
    font-style: normal;
    color: #6a1b9a;
}

.testimonial {
    max-width: 800px;
    margin: auto;
    font-size: 1.15rem; /* Reduced from 1.3rem */
    color: #444;
    display: flex;
    flex-direction: column;
    gap: 15px; /* Reduced from 25px */
}

blockquote {
    border-left: 4px solid #8e44ad; /* Reduced from 5px */
    padding: 15px 20px; /* Reduced from 20px 25px */
    margin: 8px 0; /* Reduced from 10px 0 */
    font-size: 1.15rem; /* Reduced from 1.3rem */
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 0 10px 10px 0;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.04);
    transition: transform 0.3s ease;
}

blockquote:hover {
    transform: translateX(3px); /* Reduced from 5px */
}

/* 🌟 BUTTON STYLES 🌟 */
button {
    background-color: #004a99;
    color: white;
    border: none;
    padding: 12px 24px; /* Reduced from 14px 28px */
    font-size: 1.1rem; /* Reduced from 1.2rem */
    font-weight: 600;
    border-radius: 25px;
    transition: all 0.3s ease;
    cursor: pointer;
    box-shadow: 0 3px 10px rgba(0, 74, 153, 0.25);
}

button:hover {
    background-color: #003366;
    transform: translateY(-2px); /* Reduced from -3px */
    box-shadow: 0 5px 15px rgba(0, 74, 153, 0.3);
}

/* 🎨 Typing Effect Styles */
.typing-effect {
    font-size: 2.2em; /* Reduced from 2.6em */
    font-weight: 800;
    margin-bottom: 15px; /* Reduced from 25px */
    display: inline-block;
    position: relative;
}

/* ✨ Unique Colors for Each Section Heading */
.testimonial-heading, .welcome-heading, .tools-heading, .top-down-heading, .real-life-heading, .stem-heading {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); /* Simplified shadow */
}

.cursor {
    display: inline-block;
    width: 3px;
    background-color: currentColor;
    margin-left: 2px;
    animation: blink 1s infinite;
}

@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}

/* 🌟 RESPONSIVE DESIGN 🌟 */
@media (max-width: 768px) {
    .fade-in-section {
        width: 95%;
        padding: 25px 15px; /* Reduced from 40px 20px */
    }

    h1, h2 {
        font-size: 1.8em; /* Reduced from 2em */
    }

    p {
        font-size: 1em; /* Reduced from 1.1em */
        line-height: 1.5;
    }

    blockquote {
        padding: 12px 15px; /* Reduced from 15px 20px */
        font-size: 1rem; /* Reduced from 1.1rem */
    }

    .section-video {
        max-height: 250px; /* Reduced from 300px */
    }

    /* 🌟 MOBILE HEADER FIX 🌟 */
    .Header {
        flex-direction: column;
        height: auto;
        padding: 10vh 5vw;
        text-align: center;
        box-shadow: none;
    }

    .logo-container {
        order: 1;
        display: flex;
        justify-content: center;
        margin-bottom: 2vh;
    }

    .small-logo {
        width: 20vw;
    }

    .content-container {
        order: 2;
        width: 100%;
        padding: 0 5vw;
    }

    #GreetingText {
        font-size: 8vw;
    }

    .HeaderSubtitle {
        font-size: 4vw;
        line-height: 1.5;
    }

    .dynamic-text {
        font-size: 6vw;
    }

    /* 🌟 MOBILE CTA BUTTON FIX 🌟 */
    .HeaderCta {
        font-size: 4vw;
        padding: 2vh 4vw;
        width: 80%;
        text-align: center;
        margin: 3vh auto;
        display: block;
    }

    .video-container {
        order: 3;
        display: flex;
        justify-content: center;
        margin-top: 3vh;
    }

    .small-video {
        width: 60vw;
        height: auto;
        border-radius: 10px;
    }
}

/* 🌟 CTA BUTTON CONTAINERS 🌟 */
.cta-container {
    display: flex;
    justify-content: center;
    margin-top: 15px; /* Reduced from 30px */
}

/* 🌟 PULSE BUTTON EFFECT 🌟 */
.pulse-button {
    position: relative;
    background-color: #2e7d32;
    color: white;
    border: none;
    padding: 10px 25px; /* Reduced from 14px 28px */
    border-radius: 25px;
    font-weight: bold;
    box-shadow: 0 0 0 0 rgba(46, 125, 50, 0.7);
    animation: pulse 2s infinite;
}

.pulse-button:hover {
    animation: none;
    background-color: #1b5e20;
    transform: translateY(-2px); /* Reduced from -3px */
    box-shadow: 0 5px 15px rgba(27, 94, 32, 0.35);
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(46, 125, 50, 0.7);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(46, 125, 50, 0); /* Reduced from 15px */
    }
    100% {
        box-shadow: 0 0 0 0 rgba(46, 125, 50, 0);
    }
}


