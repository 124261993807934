@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #4361ee;
  --success-color: #4CAF50;
  --warning-color: #FFC107;
  --error-color: #F44336;
  --dark-text: #333;
  --light-text: #666;
  --lighter-text: #999;
  --background-color: #f9fafb;
  --card-background: #fff;
  --border-radius: 12px;
  --box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  --gradient-primary: linear-gradient(135deg, #4361ee, #3a0ca3);
  --gradient-success: linear-gradient(135deg, #4CAF50, #2E7D32);
  --gradient-warning: linear-gradient(135deg, #FFC107, #FF8F00);
  --gradient-error: linear-gradient(135deg, #F44336, #C62828);
}

/* Main container */
.assessment-review-container {
  font-family: 'Poppins', sans-serif;
  max-width: 1000px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--card-background);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  color: var(--dark-text);
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Header styles */
.review-header {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
    text-align: center;
  color: var(--dark-text);
  position: relative;
}

.review-header::after {
  content: '';
  display: block;
  width: 100px;
  height: 4px;
  background: var(--gradient-primary);
  margin: 0.5rem auto;
  border-radius: 2px;
}

/* Summary Section */
.assessment-summary {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  gap: 2rem;
  background-color: var(--card-background);
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.score-chart {
  width: 150px;
  height: 150px;
  margin: 0 auto;
}

.score-title {
  font-size: 1.5rem;
  font-weight: 600;
    margin-bottom: 1rem;
  color: var(--dark-text);
}

.score-text, .time-text {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: var(--light-text);
}

.ability-score {
  background: var(--gradient-primary);
  padding: 1.5rem;
  border-radius: var(--border-radius);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ability-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.ability-value {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.ability-message {
  font-size: 0.95rem;
  opacity: 0.9;
}

/* Questions Review Section */
.questions-review {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: var(--card-background);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.questions-title {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: var(--dark-text);
  position: relative;
  display: block !important;
}

.questions-title::after {
  content: '';
  display: block;
  width: 80px;
  height: 3px;
  background: var(--gradient-primary);
  margin-top: 0.5rem;
  border-radius: 2px;
}

.review-list {
  list-style: none;
    padding: 0;
}

.review-item {
  background-color: var(--card-background);
  border-radius: var(--border-radius);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: var(--box-shadow);
  border-left: 5px solid transparent;
  transition: transform 0.3s ease;
}

.review-item:hover {
  transform: translateY(-5px);
}

.review-item.correct {
  border-left-color: var(--success-color);
}

.review-item.incorrect {
  border-left-color: var(--error-color);
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.question-number {
  font-weight: 600;
  font-size: 1.2rem;
  color: var(--dark-text);
}

.status-indicator {
  padding: 0.4rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}

.status-indicator.correct {
  background-color: rgba(76, 175, 80, 0.15);
  color: var(--success-color);
}

.status-indicator.incorrect {
  background-color: rgba(244, 67, 54, 0.15);
  color: var(--error-color);
}

.question-content {
  margin-bottom: 1.5rem;
}

.question-image {
  margin-bottom: 1rem;
  text-align: center;
}

.question-image img {
  max-width: 100%;
  max-height: 300px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.question-text {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  line-height: 1.5;
}

.answer-details > div {
  margin-bottom: 0.8rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.answer-details > div:last-child {
  border-bottom: none;
}

.user-answer, .correct-answer, .time-taken, .explanation {
  line-height: 1.5;
  color: var(--light-text);
}

.user-answer strong, .correct-answer strong, .time-taken strong, .explanation strong {
  color: var(--dark-text);
  margin-right: 0.5rem;
}

/* Action Buttons */
.action-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.return-button, .print-button {
  padding: 0.8rem 1.5rem;
  border-radius: 30px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  outline: none;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

.return-button {
  background-color: var(--primary-color);
  color: white;
  box-shadow: 0 4px 10px rgba(67, 97, 238, 0.3);
}

.return-button:hover {
  background-color: #3a56d4;
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(67, 97, 238, 0.4);
}

.print-button {
  background-color: white;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.print-button:hover {
  background-color: #f1f4ff;
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

/* Loading State */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(67, 97, 238, 0.2);
  border-radius: 50%;
  border-top-color: var(--primary-color);
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Error State */
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  text-align: center;
  color: var(--error-color);
}

.error-container h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.error-container p {
  margin-bottom: 2rem;
  max-width: 500px;
  line-height: 1.6;
  color: var(--light-text);
}

/* Print styles */
@media print {
  body {
    background-color: white !important;
  }
  
  .assessment-review-container {
    box-shadow: none;
    margin: 0;
    padding: 0.5in;
    max-width: 100%;
  }
  
  .action-buttons {
    display: none !important;
  }
  
  .review-item {
    break-inside: avoid;
    page-break-inside: avoid;
    box-shadow: none;
    border: 1px solid #eee;
  }
  
  .score-chart {
    width: 120px !important;
    height: 120px !important;
  }
  
  .assessment-summary {
    box-shadow: none;
    border: 1px solid #eee;
  }
  
  .questions-review {
    box-shadow: none;
  }
  
  .ability-score {
    color: black !important;
    background: #f5f5f5 !important;
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .assessment-summary {
    grid-template-columns: 1fr;
  }
  
  .score-chart {
    margin-bottom: 1.5rem;
  }
  
  .ability-score {
    margin-top: 1.5rem;
  }
  
  .assessment-review-container {
    padding: 1.5rem;
    margin: 1rem;
  }
  
  .question-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  
  .status-indicator {
    align-self: flex-start;
  }
}

@media (max-width: 480px) {
  .action-buttons {
    flex-direction: column;
    gap: 0.8rem;
  }
  
  .return-button, .print-button {
    width: 100%;
  }
}

/* Enhanced button styles */
.return-button, .print-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  padding: 0.75rem 1.5rem;
  margin: 0 0.75rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.return-button {
  background-color: #f0f2f5;
  color: var(--dark-text);
}

.return-button:hover {
  background-color: #e1e4e8;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.print-button {
  background: var(--gradient-primary);
  color: white;
}

.print-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(67, 97, 238, 0.3);
}

/* Loading spinner */
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(67, 97, 238, 0.2);
  border-top: 5px solid var(--primary-color);
  border-radius: 50%;
  margin: 2rem auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  text-align: center;
}

.error-container {
  text-align: center;
  padding: 3rem;
}

.error-container h2 {
  color: var(--error-color);
    margin-bottom: 1rem;
}

/* Animation for review items */
@keyframes slideIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.review-item {
  animation: slideIn 0.4s ease forwards;
  animation-delay: calc(var(--i, 0) * 0.1s);
}

/* Add responsive styles */
@media (max-width: 900px) {
  .assessment-summary {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  .action-buttons {
    flex-direction: column;
    align-items: center;
  }
  
  .return-button, .print-button {
    width: 100%;
    margin: 0.5rem 0;
  }
}

@media (max-width: 600px) {
  .assessment-review-container {
    padding: 1rem;
    margin: 1rem;
  }
  
  .review-header {
    font-size: 1.5rem;
  }
  
  .score-chart {
    width: 100px;
    height: 100px;
  }
  
  .ability-value {
    font-size: 2rem;
  }
}

.minimal-view-notice {
  margin: 20px 0;
  padding: 15px;
  background-color: #f8f9fa;
  border: 1px dashed #6c757d;
  border-radius: 5px;
  text-align: center;
}

.minimal-view-notice p {
  margin: 0;
  color: #6c757d;
  font-size: 16px;
}

/* Question type styling */
.question-type {
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 12px;
  background-color: #f0f0f0;
  color: #666;
}

/* Question choices styling */
.question-choices {
  margin: 15px 0;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
}

.choices-label {
  font-weight: 600;
  margin-bottom: 8px;
  color: #555;
}

.choices-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.choice-item {
  padding: 8px 12px;
  margin-bottom: 5px;
  border-radius: 4px;
  border-left: 3px solid transparent;
  background-color: white;
  transition: background-color 0.2s;
}

.choice-item.user-selected {
  border-left: 3px solid #007bff;
  background-color: #e6f2ff;
}

.choice-item.correct-choice {
  border-left: 3px solid #28a745;
  background-color: #e7f6ea;
}

.choice-item.user-selected.correct-choice {
  border-left: 3px solid #28a745;
  background-color: #d4edda;
}

.choice-marker {
  margin-right: 5px;
  font-weight: bold;
}

.choice-image {
  display: block;
  max-width: 100%;
  margin-top: 8px;
  border-radius: 4px;
}

/* Enhanced answer details */
.answer-details {
  margin-top: 15px;
  padding: 15px;
  background-color: #f8f9fa;
    border-radius: 5px;
}

.user-answer, .correct-answer, .explanation {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
}

.user-answer {
  background-color: #e6f2ff;
}

.correct-answer {
  background-color: #e7f6ea;
}

.explanation {
  background-color: #fff9e6;
  border-left: 3px solid #ffc107;
}

/* Print styles */
@media print {
  .review-item {
    page-break-inside: avoid;
    margin-bottom: 30px;
  }
  
  .choice-item.user-selected {
    font-weight: bold;
}

  .choice-item.correct-choice {
    text-decoration: underline;
  }
  
  .answer-details {
    border: 1px solid #ddd;
  }
  
  .action-buttons {
    display: none;
  }
}
