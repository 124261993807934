/* BlogList.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Merriweather:wght@300;400;700&display=swap');

/* Main page layout */
.blog-page {
    font-family: 'Montserrat', sans-serif;
    color: #333;
    background-color: #f8f9fa;
    min-height: 100vh;
}

/* Header section */
.blog-header {
    background: linear-gradient(135deg, #2c3e50, #3498db);
    color: white;
    padding: 80px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
}

.blog-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('https://images.unsplash.com/photo-1499750310107-5fef28a66643?ixlib=rb-4.0.3&auto=format&fit=crop&w=1920&q=80') center/cover;
    opacity: 0.2;
    z-index: 0;
}

.blog-header.scrolled {
    padding: 30px 20px;
    background: rgba(44, 62, 80, 0.95);
}

.header-content {
    position: relative;
    z-index: 1;
    max-width: 900px;
    margin: 0 auto;
}

.blog-header h1 {
    font-size: 3.5rem;
    margin-bottom: 15px;
    font-weight: 700;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.blog-header p {
    font-size: 1.2rem;
    max-width: 700px;
    margin: 0 auto;
    opacity: 0.9;
}

/* Blog container */
.blog-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
}

/* Loading state */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(52, 152, 219, 0.2);
    border-radius: 50%;
    border-top-color: #3498db;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
}

@keyframes spin {
    to { transform: rotate(360deg); }
}

/* Error message */
.error-message {
    background-color: #fff;
    border-left: 4px solid #e74c3c;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    margin: 20px 0;
}

.error-message h3 {
    color: #e74c3c;
    margin-top: 0;
}

/* Blog controls */
.blog-controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    gap: 20px;
}

.search-wrapper {
    position: relative;
    flex: 1;
    min-width: 250px;
}

.search-bar {
    width: 100%;
    padding: 12px 40px 12px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 30px;
    font-size: 16px;
    transition: all 0.3s ease;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    font-family: 'Montserrat', sans-serif;
}

.search-bar:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 3px 10px rgba(52, 152, 219, 0.15);
}

.search-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
    font-style: normal;
}

.filter-controls {
    display: flex;
    gap: 15px;
}

.category-filter,
.sort-filter {
    padding: 10px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 30px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #555;
    min-width: 160px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.category-filter:focus,
.sort-filter:focus {
    outline: none;
    border-color: #3498db;
}

/* Featured post */
.featured-post {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    margin-bottom: 50px;
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.featured-post:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
}

.featured-image {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.featured-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.featured-post:hover .featured-image img {
    transform: scale(1.05);
}

.featured-content {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.post-meta {
    display: flex;
    gap: 15px;
    font-size: 14px;
    color: #777;
    margin-bottom: 5px;
    flex-wrap: wrap;
}

.post-category {
    color: #3498db;
    font-weight: 600;
    background-color: rgba(52, 152, 219, 0.1);
    padding: 3px 10px;
    border-radius: 20px;
}

.post-date, .post-readtime {
    display: flex;
    align-items: center;
}

.featured-title {
    margin: 0;
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 700;
}

.featured-title a {
    color: #333;
    text-decoration: none;
    transition: color 0.2s ease;
}

.featured-title a:hover {
    color: #3498db;
}

.featured-excerpt {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin: 10px 0 20px;
    font-family: 'Merriweather', serif;
    font-weight: 300;
}

.post-author {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: auto;
}

.author-avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #3498db;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}

.author-name {
    font-size: 14px;
    font-weight: 500;
}

.read-more-button {
    display: inline-block;
    background-color: #3498db;
    color: white;
    padding: 12px 25px;
    border-radius: 30px;
    text-decoration: none;
    font-weight: 600;
    margin-top: 20px;
    align-self: flex-start;
    transition: background-color 0.3s ease;
}

.read-more-button:hover {
    background-color: #2980b9;
}

/* Posts grid */
.posts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 30px;
    margin-top: 20px;
}

.post-card {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.post-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.card-image-container {
    position: relative;
    height: 200px;
    overflow: hidden;
}

.post-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.post-card:hover .post-image {
    transform: scale(1.05);
}

.post-category-tag {
    position: absolute;
    top: 15px;
    left: 15px;
    background-color: rgba(52, 152, 219, 0.9);
    color: white;
    padding: 5px 12px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 600;
}

.post-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.post-title {
    font-size: 1.25rem;
    margin: 10px 0;
    line-height: 1.4;
}

.post-title a {
    color: #333;
    text-decoration: none;
    transition: color 0.2s ease;
}

.post-title a:hover {
    color: #3498db;
}

.post-snippet {
    font-size: 0.95rem;
    line-height: 1.6;
    color: #666;
    margin-bottom: 15px;
    flex-grow: 1;
    font-family: 'Merriweather', serif;
    font-weight: 300;
}

.post-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    padding-top: 15px;
    border-top: 1px solid #f0f0f0;
}

.delete-button {
    background: none;
    border: none;
    color: #e74c3c;
    cursor: pointer;
    font-size: 18px;
    padding: 5px;
    transition: transform 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-button:hover {
    transform: scale(1.15);
}

.no-posts-message {
    text-align: center;
    padding: 60px 20px;
    grid-column: 1 / -1;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.no-posts-message h3 {
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 10px;
}

.no-posts-message p {
    color: #777;
}

/* Media queries */
@media (min-width: 768px) {
    .featured-post {
        grid-template-columns: 1fr 1fr;
    }
    
    .featured-image {
        height: auto;
    }
}

@media (max-width: 767px) {
    .blog-header h1 {
        font-size: 2.5rem;
    }
    
    .blog-header p {
        font-size: 1rem;
    }
    
    .posts-grid {
        grid-template-columns: 1fr;
    }
    
    .blog-controls {
        flex-direction: column;
        align-items: stretch;
    }
    
    .filter-controls {
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .blog-header {
        padding: 50px 15px;
    }
    
    .blog-header h1 {
        font-size: 2rem;
    }
    
    .featured-title {
        font-size: 1.5rem;
    }
    
    .featured-excerpt {
        font-size: 1rem;
    }
}
