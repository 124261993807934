/* AdaptiveAssessments.css */
/* Modern medical-themed styling for adaptive assessments */

:root {
  --primary: #1976d2; /* Medical blue */
  --primary-light: #63a4ff;
  --primary-dark: #004ba0;
  --secondary: #26a69a; /* Teal accent */
  --secondary-light: #64d8cb;
  --secondary-dark: #00766c;
  --text-dark: #333333;
  --text-light: #ffffff;
  --text-muted: #6c757d;
  --background-light: #f8f9fa;
  --background-card: #ffffff;
  --success: #4caf50;
  --warning: #ff9800;
  --error: #f44336;
  --gray-100: #f8f9fa;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Outer Container for Adaptive Assessments */
.adaptive-container {
  background-color: var(--background-light);
  padding: 0;
  margin: 0 auto;
  max-width: 1600px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  color: var(--text-dark);
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
}

/* Hero Section */
.hero-section {
  background: linear-gradient(135deg, var(--primary-light), var(--primary));
  color: var(--text-light);
  padding: 4rem 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 20px 20px;
  position: relative;
  overflow: hidden;
}

.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffffff' fill-opacity='0.08' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.7;
}

.hero-content {
  flex: 1;
  min-width: 300px;
  z-index: 1;
}

.hero-content h1 {
  font-size: 2.8rem;
  margin-bottom: 1rem;
  font-weight: 700;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  max-width: 600px;
  line-height: 1.6;
  opacity: 0.9;
}

.hero-image {
  flex: 0 0 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

#Ada {
  width: 280px;
  height: 280px;
  object-fit: contain;
  filter: drop-shadow(0 8px 16px rgba(0, 0, 0, 0.2));
  transition: transform 0.3s ease;
}

#Ada:hover {
  transform: scale(1.05);
}

/* Features Section */
.irt-cat-intro {
  background-color: var(--background-card);
  border-radius: 12px;
  padding: 3rem 2rem;
  margin: 2rem;
  box-shadow: var(--shadow);
}

.irt-cat-intro h2 {
  color: var(--primary-dark);
  margin-bottom: 2rem;
  font-size: 2.2rem;
  text-align: center;
  font-weight: 600;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
}

.feature-card {
  background-color: var(--gray-100);
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.feature-card h3 {
  color: var(--primary-dark);
  margin-bottom: 1rem;
  font-weight: 600;
}

.feature-card p {
  color: var(--text-muted);
  line-height: 1.6;
}

/* Assessments Section */
.assessments-section {
  padding: 3rem 2rem;
}

.assessments-section h2 {
  font-size: 2.2rem;
  color: var(--text-dark);
  text-align: center;
  margin-bottom: 2.5rem;
  font-weight: 600;
}

.assessment-filters {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.search-bar {
  padding: 0.8rem 1.5rem;
  font-size: 1.1rem;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.2s ease;
}

.search-bar:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--primary-light);
  border-color: var(--primary);
}

.category-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  justify-content: center;
}

.category-button {
  padding: 0.6rem 1.2rem;
  border: none;
  background-color: var(--gray-200);
  border-radius: 8px;
  font-weight: 500;
  color: var(--text-muted);
  cursor: pointer;
  transition: all 0.2s ease;
}

.category-button:hover {
  background-color: var(--gray-300);
}

.category-button.active {
  background-color: var(--primary);
  color: var(--text-light);
}

.assessments-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 2rem;
}

.assessment-card {
  border-radius: 12px;
  overflow: hidden;
  background-color: var(--background-card);
  box-shadow: var(--shadow);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
}

.assessment-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
}

.assessment-card-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.assessment-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-bottom: 1px solid var(--gray-200);
}

.assessment-content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.assessment-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--text-dark);
  margin-bottom: 0.8rem;
}

.assessment-description {
  font-size: 0.95rem;
  color: var(--text-muted);
  line-height: 1.6;
  margin-bottom: 1.5rem;
  flex-grow: 1;
}

.assessment-meta {
  display: flex;
  gap: 0.8rem;
  margin-bottom: 1.2rem;
}

.assessment-badge {
  display: inline-block;
  padding: 0.3rem 0.8rem;
  border-radius: 50px;
  font-size: 0.8rem;
  font-weight: 500;
  background-color: var(--gray-200);
  color: var(--text-dark);
}

.assessment-badge.premium {
  background-color: var(--warning);
  color: var(--text-light);
}

.assessment-badge.free {
  background-color: var(--success);
  color: var(--text-light);
}

.start-assessment-button,
.add-to-cart-button {
  padding: 0.8rem 1.2rem;
  text-align: center;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  transition: background-color 0.2s ease, transform 0.2s ease;
  width: 100%;
}

.start-assessment-button {
  background-color: var(--primary);
  color: var(--text-light);
}

.start-assessment-button:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

.add-to-cart-button {
  background-color: var(--secondary);
  color: var(--text-light);
}

.add-to-cart-button:hover {
  background-color: var(--secondary-dark);
  transform: translateY(-2px);
}

.loading-spinner,
.error-message,
.no-results {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: var(--text-muted);
}

.error-message {
  color: var(--error);
}

/* Testimonials Section */
.testimonial-section {
  background-color: var(--gray-100);
  padding: 4rem 2rem;
  border-radius: 12px;
  margin: 2rem;
}

.testimonial-section h2 {
  text-align: center;
  font-size: 2.2rem;
  color: var(--text-dark);
  margin-bottom: 3rem;
  font-weight: 600;
}

.testimonial-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
}

.testimonial-card {
  background-color: var(--background-card);
  border-radius: 12px;
  padding: 2rem;
  box-shadow: var(--shadow);
  position: relative;
}

.testimonial-card::before {
  content: '"';
  position: absolute;
  top: 10px;
  left: 15px;
  font-size: 4rem;
  color: var(--primary-light);
  opacity: 0.2;
  font-family: Georgia, serif;
}

.testimonial-card p {
  font-size: 1.1rem;
  line-height: 1.7;
  margin-bottom: 1.5rem;
  font-style: italic;
  position: relative;
  z-index: 1;
}

.testimonial-author {
  font-weight: 600;
  color: var(--primary-dark);
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .hero-section {
    padding: 3rem 1.5rem;
    flex-direction: column;
    text-align: center;
  }
  
  .hero-content h1 {
    font-size: 2.4rem;
  }
  
  .hero-content p {
    font-size: 1.1rem;
    margin: 0 auto 2rem;
  }
  
  .hero-image {
    margin-top: 2rem;
  }
  
  #Ada {
    width: 220px;
    height: 220px;
  }
  
  .irt-cat-intro,
  .testimonial-section {
    margin: 1.5rem;
    padding: 2rem 1.5rem;
  }
  
  .assessments-section {
    padding: 2rem 1.5rem;
  }
}

@media (max-width: 576px) {
  .hero-content h1 {
    font-size: 2rem;
  }
  
  .assessments-grid {
    grid-template-columns: 1fr;
  }
  
  #Ada {
    width: 180px;
    height: 180px;
  }
  
  .irt-cat-intro,
  .testimonial-section {
    margin: 1rem;
    padding: 1.5rem 1rem;
  }
  
  .assessment-filters {
    gap: 1rem;
  }
  
  .category-button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}
