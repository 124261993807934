.chat-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-color: #f4f4f9; /* Subtle background color */
    font-family: 'Arial', sans-serif;
    max-width: 100%; /* Limit container width */
  }
  
  .sidebar {
    flex: 1;
    background-color: #2c3e50; /* Darker teal for sidebar */
    color: #ffffff;
    padding: 20px;
    overflow-y: auto;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Slight shadow for separation */
  }
  
  .chat-area {
    flex: 3;
    display: flex;
    flex-direction: column;
    background-color: #ffffff; /* White background for chat area */
    border-left: 1px solid #e0e0e0; /* Subtle border */
  }
  
  .chat-header {
    background-color: #3498db; /* Bright blue */
    color: #ffffff;
    padding: 15px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Shadow for header */
  }
  
  .messages {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Add space between messages */
    background-color: #f9f9f9; /* Light background for messages */
    border-top: 1px solid #e0e0e0;
  }
  
  .message-wrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    max-width: 100%;
  }
  
  .message-bubble {
    max-width: calc(100% - 60px);
    padding: 15px;
    border-radius: 18px;
    border-top-left-radius: 5px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.06);
    word-wrap: break-word;
    font-size: 14px;
    line-height: 1.6;
    background: linear-gradient(to bottom right, #ffffff, #f8f9fa);
    color: #333333;
    border: 1px solid rgba(0, 0, 0, 0.05);
    position: relative;
  }
  
  .message-bubble::before {
    content: '';
    position: absolute;
    top: 0;
    left: -8px;
    width: 16px;
    height: 16px;
    background: linear-gradient(135deg, #ffffff, #f8f9fa);
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    transform: rotate(45deg);
    border-radius: 0 0 0 4px;
  }
  
  .sent .message-bubble {
    background-color: #3498db; /* Bright blue for sent messages */
    color: #ffffff; /* White text for contrast */
    align-self: flex-end;
    border-bottom-right-radius: 5px;
  }
  
  .received .message-bubble {
    background-color: #eaeaea; /* Light gray for received messages */
    color: #333333; /* Dark text for readability */
    align-self: flex-start;
    border-top-left-radius: 5px;
  }
  
  .message-bubble:hover {
    transform: scale(1.02); /* Slight zoom effect on hover */
    transition: transform 0.2s ease-in-out;
  }
  
  .message-input {
    background-color: #ffffff;
    padding: 15px;
    border-top: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .message-input textarea {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    resize: none;
    font-size: 14px;
    font-family: 'Arial', sans-serif;
  }
  
  .message-input button {
    background-color: #3498db;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .message-input button:hover {
    background-color: #2176ba; /* Slightly darker blue on hover */
  }
  
  .loading {
    text-align: center;
    font-size: 16px;
    color: #3498db;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .ellipsis span {
    display: inline-block;
    animation: blink 1.4s infinite;
  }
  
  .ellipsis span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .ellipsis span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes blink {
    0%, 20% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
  
  .mini-chat {
    width: 100%;
    max-width: 500px;
    height: auto;
    min-height: 300px;
    max-height: 80vh;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 15px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .mini-chat .chat-header {
    background: linear-gradient(45deg, #6a11cb 0%, #2575fc 100%);
    color: white;
    padding: 15px 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    letter-spacing: 0.5px;
  }
  
  .mini-chat .chat-header .bot-avatar {
    width: 28px;
    height: 28px;
    margin-right: 10px;
    border: 2px solid rgba(255, 255, 255, 0.8);
  }
  
  .mini-chat .chat-messages {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    max-height: 400px;
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column;
    gap: 15px;
    scrollbar-width: thin;
    scrollbar-color: rgba(106, 17, 203, 0.2) transparent;
  }
  
  /* Custom scrollbar for Webkit browsers */
  .mini-chat .chat-messages::-webkit-scrollbar {
    width: 8px;
  }
  
  .mini-chat .chat-messages::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  
  .mini-chat .chat-messages::-webkit-scrollbar-thumb {
    background-color: rgba(106, 17, 203, 0.2);
    border-radius: 10px;
    border: 2px solid transparent;
  }
  
  .mini-chat .chat-messages::-webkit-scrollbar-thumb:hover {
    background-color: rgba(106, 17, 203, 0.4);
  }
  
  .message-wrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    max-width: 100%;
    animation: fadeIn 0.3s ease-out;
  }
  
  .bot-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 12px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid white;
    flex-shrink: 0;
  }
  
  .message-bubble {
    max-width: calc(100% - 60px);
    padding: 15px;
    border-radius: 18px;
    border-top-left-radius: 5px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.06);
    word-wrap: break-word;
    font-size: 14px;
    line-height: 1.6;
    background: linear-gradient(to bottom right, #ffffff, #f8f9fa);
    color: #333333;
    border: 1px solid rgba(0, 0, 0, 0.05);
    position: relative;
  }
  
  .message-bubble::before {
    content: '';
    position: absolute;
    top: 0;
    left: -8px;
    width: 16px;
    height: 16px;
    background: linear-gradient(135deg, #ffffff, #f8f9fa);
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    transform: rotate(45deg);
    border-radius: 0 0 0 4px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(5px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Mobile Styles for Mini-Chat */
  @media (max-width: 576px) {
    .mini-chat {
      width: 100%;
      max-width: 100%;
      height: auto;
      min-height: 250px;
      max-height: 60vh;
      position: fixed;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      margin-top: 0;
      border-radius: 15px 15px 0 0;
      box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.2);
    }
    
    .mini-chat .chat-header {
      padding: 12px 15px;
      font-size: 14px;
      position: relative;
    }
    
    .mini-chat .chat-header::before {
      content: '';
      position: absolute;
      top: 6px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 4px;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 2px;
    }
    
    .mini-chat .chat-messages {
      padding: 15px;
      max-height: calc(60vh - 50px);
    }
    
    .message-wrapper {
      margin-bottom: 12px;
    }
    
    .bot-avatar {
      width: 32px;
      height: 32px;
      margin-right: 10px;
    }
    
    .message-bubble {
      padding: 12px;
      font-size: 13px;
      line-height: 1.4;
      max-width: calc(100% - 50px);
    }
    
    .message-bubble::before {
      width: 12px;
      height: 12px;
      left: -6px;
    }
  }