/* Friends List Main Container */
.friends-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  height: 100%;
}

/* Friends List Section Title */
.friends-list h3 {
  font-size: 1.2rem;
  color: var(--text-dark);
  font-weight: 600;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 8px;
}

.friends-list h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 3px;
  background: var(--primary-color);
  border-radius: 3px;
}

/* Friends List Container */
.friends-list ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

/* Friend Item Row in List */
.friend-item {
  display: flex;
  align-items: center;
  background-color: var(--bg-lighter);
  margin-bottom: 10px;
  padding: 12px 15px;
  border-radius: 10px;
  box-shadow: var(--box-shadow-sm);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.friend-item:hover {
  transform: translateY(-3px);
  box-shadow: var(--box-shadow-md);
}

/* Status Indicator Light */
.status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 12px;
  position: relative;
}

.status-indicator::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 50%;
  animation: pulse 2s infinite;
  opacity: 0;
}

.online {
  background-color: var(--success);
}

.online::after {
  background-color: var(--success);
}

.offline {
  background-color: var(--text-medium);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  70% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/* Friend Name Text */
.friend-name {
  font-weight: 600;
  margin-right: auto;
  color: var(--text-dark);
}

/* Status Text Styling */
.status-text {
  font-size: 0.8rem;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 12px;
}

.online-text {
  color: var(--success);
  background-color: rgba(16, 185, 129, 0.1);
}

.offline-text {
  color: var(--text-medium);
  background-color: rgba(100, 116, 139, 0.1);
}

/* Send Friend Request Section */
.send-friend-request {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.send-friend-request select {
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--gray-300);
  background-color: var(--bg-lighter);
  font-family: 'Poppins', sans-serif;
  outline: none;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.send-friend-request select:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.2);
}

.send-friend-request button {
  padding: 10px 15px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;
  white-space: nowrap;
}

.send-friend-request button:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

.send-friend-request button:disabled {
  background-color: var(--gray-300);
  cursor: not-allowed;
  transform: none;
}

/* Friend Request Item */
.friend-request-item,
.outgoing-request-item {
  display: flex;
  align-items: center;
  background-color: var(--bg-lighter);
  margin-bottom: 10px;
  padding: 12px 15px;
  border-radius: 10px;
  box-shadow: var(--box-shadow-sm);
  flex-wrap: wrap;
  gap: 10px;
}

.friend-request-item span,
.outgoing-request-item span {
  font-weight: 600;
  margin-right: auto;
  color: var(--text-dark);
}

.friend-request-item button,
.outgoing-request-item button {
  padding: 6px 12px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.friend-request-item button:first-of-type {
  background-color: var(--primary-color);
  color: white;
}

.friend-request-item button:first-of-type:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

.friend-request-item button:last-of-type,
.outgoing-request-item button {
  background-color: var(--gray-200);
  color: var(--text-dark);
}

.friend-request-item button:last-of-type:hover,
.outgoing-request-item button:hover {
  background-color: var(--gray-300);
  transform: translateY(-2px);
}

/* Message Styling */
.friends-list > p {
  color: var(--text-medium);
  font-size: 0.9rem;
  padding: 10px;
  background-color: var(--bg-lighter);
  border-radius: 8px;
  border-left: 3px solid var(--primary-color);
}

/* Responsive Design */
@media (max-width: 768px) {
  .friends-list {
    padding: 15px;
  }

  .send-friend-request {
    flex-direction: column;
  }

  .friend-request-item button,
  .outgoing-request-item button {
    padding: 5px 10px;
    font-size: 0.75rem;
  }

  .status-indicator {
    width: 8px;
    height: 8px;
  }
}
