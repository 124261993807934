/* Video Library Container */
.video-library-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px 20px;
    font-family: 'Poppins', sans-serif;
    background-color: #f9f9f9;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    margin-bottom: 40px;
  }
  
  /* Library Header */
  .video-library-header {
    text-align: center;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eaeaea;
  }
  
  .video-library-title {
    font-size: 2.4rem;
    color: #003366;
    margin-bottom: 8px;
    font-weight: 700;
  }
  
  .video-library-subtitle {
    font-size: 1.1rem;
    color: #666;
    margin: 0;
  }
  
  /* Search and Filter Controls */
  .video-library-controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    gap: 15px;
  }
  
  .search-container {
    flex: 1;
    min-width: 250px;
  }
  
  .search-bar {
    width: 100%;
    padding: 12px 20px;
    font-size: 1rem;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    color: #333;
    background-color: white;
  }
  
  .search-bar:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 2px 10px rgba(74, 144, 226, 0.2);
  }
  
  .category-filter {
    width: 220px;
  }
  
  .category-select {
    width: 100%;
    padding: 12px 20px;
    font-size: 1rem;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    background-color: white;
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23333'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 20px;
  }
  
  .category-select:focus {
    outline: none;
    border-color: #4a90e2;
  }
  
  /* Results Info */
  .video-results-info {
    font-size: 0.95rem;
    color: #666;
    margin-bottom: 20px;
    padding: 10px 15px;
    background-color: #f0f0f0;
    border-radius: 6px;
  }
  
  /* Videos Grid */
  .videos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 25px;
    padding: 0;
  }
  
  /* Video Card */
  .video-card-link {
    text-decoration: none;
    color: inherit;
    display: block;
  }
  
  .video-card {
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
    transition: all 0.3s ease;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    border: 1px solid #eaeaea;
  }
  
  .video-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  }
  
  /* Thumbnail Container */
  .video-thumbnail-container {
    position: relative;
    overflow: hidden;
    border-radius: 12px 12px 0 0;
  }
  
  /* Video Image */
  .video-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
    transition: transform 0.5s ease;
    display: block;
  }
  
  .video-card:hover .video-image {
    transform: scale(1.05);
  }
  
  /* Play Button */
  .video-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .video-play-button svg {
    width: 24px;
    height: 24px;
  }
  
  .video-card:hover .video-play-button {
    opacity: 1;
  }
  
  /* Video Content */
  .video-content {
    padding: 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .video-title-list {
    font-size: 1.1rem;
    font-weight: 600;
    color: #333;
    margin: 0 0 10px 0;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .video-category, .video-date {
    font-size: 0.85rem;
    display: inline-block;
    margin-top: auto;
  }
  
  .video-category {
    background-color: #e6f2ff;
    color: #0066cc;
    padding: 3px 8px;
    border-radius: 4px;
    margin-right: 8px;
  }
  
  .video-date {
    color: #666;
  }
  
  /* Loading and Error States */
  .video-list-loading, .video-list-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    text-align: center;
    padding: 20px;
  }
  
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #003366;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .video-list-error p {
    color: #e53935;
    font-size: 1.1rem;
  }
  
  /* No Videos Found */
  .no-videos-found {
    grid-column: 1 / -1;
    padding: 30px;
    text-align: center;
    background-color: #f0f0f0;
    border-radius: 8px;
    color: #666;
  }
  
  /* Responsive Design */
  @media (max-width: 992px) {
    .videos-grid {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      gap: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .video-library-container {
      padding: 20px 15px;
    }
    
    .video-library-controls {
      flex-direction: column;
      align-items: stretch;
    }
    
    .search-container, .category-filter {
      width: 100%;
    }
    
    .video-library-title {
      font-size: 2rem;
    }
    
    .videos-grid {
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
      gap: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .videos-grid {
      grid-template-columns: 1fr;
    }
    
    .video-image {
      height: 200px;
    }
  }
  